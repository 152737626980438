import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle, Button } from '@kiiwi/ui';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import CameraOrMicAlert from '@/components/molecules/CameraOrMicAlert';
import {
  NetworkTestingErrorNames,
  OpenTokNetworkTestingContextValue,
} from '@/src/contexts/OpenTokNetworkTestingContext/OpenTokNetworkTestingContext';

interface Props
  extends Pick<
    OpenTokNetworkTestingContextValue,
    'getSimplifiedError' | 'networkTestResult' | 'retryLatestTest'
  > {}

const TlcErrorsBeforeMeet = ({
  retryLatestTest,
  getSimplifiedError,
  networkTestResult,
}: Props) => {
  const { t } = useTranslation();
  /**
   * Display one error at time
   * {undefined} means no error
   * {null} means default error
   */
  const [simplifiedError, setSimplifiedError] = useState<
    | ReturnType<OpenTokNetworkTestingContextValue['getSimplifiedError']>
    | undefined
    | null
  >(undefined);

  useEffect(() => {
    if (
      !networkTestResult.connectivityTest.error &&
      !networkTestResult.qualityTest.error
    ) {
      setSimplifiedError(undefined);
      return;
    }

    let _simplifiedError: typeof simplifiedError;
    try {
      const error =
        networkTestResult.qualityTest.error ||
        networkTestResult.connectivityTest.error;
      _simplifiedError = error === null ? error : getSimplifiedError(error);
    } catch (e) {
      _simplifiedError = null;
    }
    setSimplifiedError(_simplifiedError);
  }, [networkTestResult]);

  if (simplifiedError === undefined) return null;

  const { title, content, canRetry, icon } = (() => {
    if (
      simplifiedError?.simplifiedName === NetworkTestingErrorNames.CODE_ERROR
    ) {
      return {
        icon: undefined,
        canRetry: true,
        title: t('tlc_pre_call_error__default_error__title'),
        content: t('tlc_pre_call_error__default_error__content'),
      };
    }

    if (
      simplifiedError?.simplifiedName ===
      NetworkTestingErrorNames.OT_SERVER_ERROR
    ) {
      return {
        canRetry: true,
        title: t('tlc_pre_call_error__ot_server_error__title'),
        content: t('tlc_pre_call_error__ot_server_error__content'),
        icon: <FontAwesomeIcon name="wifi-exclamation:solid" />,
      };
    }
    if (
      simplifiedError?.simplifiedName ===
      NetworkTestingErrorNames.OT_PUBLISHER_OR_SUBSCRIBER_NETWORK_ERROR
    ) {
      return {
        canRetry: true,
        title: t('tlc_pre_call_error__network_error__title'),
        content: t('tlc_pre_call_error__network_error__content'),
        icon: <FontAwesomeIcon name="wifi-exclamation:solid" />,
      };
    }

    if (
      simplifiedError?.simplifiedName ===
      NetworkTestingErrorNames.UNSUPPORTED_BROWSER
    ) {
      return {
        canRetry: true,
        title: t('tlc_pre_call_error__unsupported_browser__title'),
        content: t('tlc_pre_call_error__unsupported_browser__content'),
        icon: <FontAwesomeIcon name="browser:regular" />,
      };
    }

    if (
      simplifiedError?.simplifiedName ===
      NetworkTestingErrorNames.UNSUPPORTED_RESOLUTION_ERROR
    ) {
      return {
        canRetry: true,
        title: t('tlc_pre_call_error__unsupported_browser__title'),
        content: t('tlc_pre_call_error__unsupported_browser__content'),
        icon: <FontAwesomeIcon name="browser:regular" />,
      };
    }

    return {
      icon: undefined,
      canRetry: true,
      title: t('tlc_pre_call_error__default_error__title'),
      content: t('tlc_pre_call_error__default_error__content'),
    };
  })();

  if (
    simplifiedError &&
    [
      NetworkTestingErrorNames.MEDIA_REQUIRED,
      NetworkTestingErrorNames.MEDIA_PERMISSIONS,
    ].includes(simplifiedError.simplifiedName)
  )
    return (
      <div>
        <CameraOrMicAlert
          footer={
            canRetry && (
              <div>
                <Button
                  size="sm"
                  variant="secondary"
                  color="danger"
                  onClick={retryLatestTest}
                  className="mt-3"
                >
                  {t('retry')}
                </Button>
              </div>
            )
          }
        />
      </div>
    );

  return (
    <Alert variant="error" icon={icon}>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{content}</AlertDescription>
      {canRetry && (
        <div>
          <Button
            size="sm"
            variant="secondary"
            color="danger"
            onClick={retryLatestTest}
            className="mt-3"
          >
            {t('retry')}
          </Button>
        </div>
      )}
    </Alert>
  );
};

export default TlcErrorsBeforeMeet;
