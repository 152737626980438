/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Tanstack Query Version : `4`
 *
 * Implementation for `proChatRoomsByChatRoomId` hook
 * Endpoint: `/pro/chat-rooms/{chatRoomId}`
 */

import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

import { useApiClient } from "../../../core/react-query";

export function makeQueryKey(params) {
	return ["@@api-pro", "pro", "chat-rooms", params.chatRoomId];
}

export function useInvalidateQuery() {
	const queryClient = useQueryClient();
	return function invalidateQuery(params) {
		return queryClient.invalidateQueries({
			queryKey: makeQueryKey(params).filter(Boolean),
		});
	};
}

export function useGet(params, options) {
	return useQuery({
		...options,
		queryKey: makeQueryKey(params),
		// only enable hook if all required params are passed at runtime (don't trust the developer who could cast)
		enabled: !!params.chatRoomId && options?.enabled !== false,
	});
}

export function useDelete(mutationOptions) {
	const { getApiClient } = useApiClient();
	// todo: add invalidateQuery mechanism
	return useMutation({
		...mutationOptions,
		mutationFn: (variables) => {
			return getApiClient({ apiName: "api-pro" })
				.delete(
					`/pro/chat-rooms/${variables.chatRoomId}`,

					{},
				)
				.then(({ data }) => data);
		},
	});
}

export function usePatch(mutationOptions) {
	const { getApiClient } = useApiClient();
	// todo: add invalidateQuery mechanism
	return useMutation({
		...mutationOptions,
		mutationFn: (variables) => {
			return getApiClient({ apiName: "api-pro" })
				.patch(
					`/pro/chat-rooms/${variables.chatRoomId}`,
					variables.$payload,
					{},
				)
				.then(({ data }) => data);
		},
	});
}
