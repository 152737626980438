import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useKeycloak } from '@react-keycloak/ssr';
import { KeycloakInstance } from 'keycloak-js';
import snakeCase from 'lodash/snakeCase';

import { serialActions } from '@docavenue/core';

import ConsultationReasonsTimeslotRefContextProvider from '../components/contexts/ConsultationReasonsTimeslotRefContext';
import ExportStatisticsDocumentHandler from '../components/molecules/ExportStatisticsDocumentHandler';
import { useDisplayAutoOnboardingWelcomeDialog } from '../components/organisms/AutoOnboardingWelcomeDialog/AutoOnboardingWelcomeDialog';
import DocumentOverlay from '../components/organisms/DocumentOverlay';
import HeaderIsFreemium from '../components/organisms/Header/HeaderIsFreemium';
import MaintenancePage from '../components/organisms/MaintenancePage';
import MiniVideoSessionPendingDialog from '../components/organisms/MiniVideoSessionPendingDialog/MiniVideoSessionPendingDialog';
import DynatraceDebugToken from '../components/utils/components/Debug/DynatraceDebugToken';
import { complexFormActions, patientsActions } from './actions';
import config from './config';
import Routes from './constants/routes';
import { runSerialActionsWithRoutingContext } from './hoc/layoutWrapperFunctions';
import securedPage from './hoc/securedPage';
import { useOpenComplexForm } from './hooks/actions';
import { useMaintenanceMaiia } from './hooks/maintenance';
import {
  useCenterId,
  useIsFreemium,
  useIsPractitioner,
  useUserId,
} from './hooks/selectors';
import { useSerialActionsAsRouter } from './hooks/serialActionsOnRouterEvents';
import { useSearchParams } from './hooks/utils';
import Layout from './Layout';
import { RootState } from './reducer/rootState';
import {
  isPathWithoutHeader,
  isPathWithSidebar,
  isValidRoute,
} from './utils/RoutesUtils';
import { isTabWithSidebar } from './utils/TabsUtils';

import { DatacyContext } from '@/src/contexts';
import { setScreebIdentity } from '@/src/screeb';

const Header = dynamic(() => import('../components/organisms/Header'));

const SideBar = dynamic(() => import('../components/organisms/SideBar'));
const SocketSuscribers = dynamic(() => import('./SocketSuscribers'));
const DialogMaster = dynamic(() =>
  import('../components/organisms/DialogMaster'),
);

const AutoOnboardingWelcomeDialog = dynamic(() =>
  import(
    '../components/organisms/AutoOnboardingWelcomeDialog/AutoOnboardingWelcomeDialog'
  ),
);

const ConfirmLogoutDialog = dynamic(() =>
  import('../components/organisms/ConfirmLogoutDialog'),
);

const ClearAccessEndDateDialog = dynamic(() =>
  import('../components/organisms/ClearAccessEndDateDialog'),
);

const NotificationsListener = dynamic(() =>
  import('../components/organisms/NotificationsListener/NotificationsListener'),
);
const PaymentErrorsDialog = dynamic(() =>
  import('../components/organisms/PaymentErrorsDialog'),
);

const VideoSessionPendingDialog = dynamic(() =>
  import('../components/organisms/VideoSessionPendingDialog'),
);
const TlcReminderDialog = dynamic(() =>
  import('../components/organisms/TlcReminderDialog'),
);
const TlcDeactivateDialog = dynamic(() =>
  import('../components/organisms/TlcDeactivateDialog'),
);
const TlcErrorsDialog = dynamic(() =>
  import('../components/organisms/TlcErrorsDialog'),
);
const WatchPingPatient = dynamic(() =>
  import('../components/organisms/WatchPingPatient'),
);
const SnackBuildContainer = dynamic(() =>
  import(
    '../components/metaOrganisms/snacks/Release/containers/SnackBuildContainer'
  ),
);
const FusionDialogContainer = dynamic(() =>
  import('../components/organisms/FusionDialog/FusionDialogContainer'),
);
const AutomaticTlcSuggestionDialog = dynamic(() =>
  import('../components/organisms/AutomaticTlcSuggestion'),
);
const PrivacyAndTermsUpdateModal = dynamic(() =>
  import('../components/organisms/PrivacyAndTermsUpdateModal'),
);
type Props = {
  children: ReactNode;
  token: string;
};

const hideHeader = pathname =>
  isPathWithoutHeader(pathname) || !isValidRoute(pathname);

const LayoutWrapper = (props: Props) => {
  const { children, token } = props;
  const isPractitioner = useIsPractitioner();
  const userId = useUserId();
  const dispatch = useDispatch();
  const isFreemium = useIsFreemium();
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  const { query, pathname = Routes.ROOT } = useRouter();

  const searchParams = useSearchParams();
  const tab = searchParams.get('tab') ?? '';

  const { appointmentId, patientPhoneNumber, apptPatientId, practitionerId } =
    query || {};
  const complexFormData = useSelector(state => state.complexForm.item);

  const isUp = useMaintenanceMaiia();
  const openComplexForm = useOpenComplexForm();
  const centerId = useCenterId();
  const {
    canDisplayDialog: canDisplayWelcomeDialog,
    handleCloseDialog: handleCloseWelcomeDialog,
  } = useDisplayAutoOnboardingWelcomeDialog();

  useSerialActionsAsRouter(runSerialActionsWithRoutingContext);

  useEffect(() => {
    if (patientPhoneNumber && pathname !== Routes.CREATE_APPOINTMENT) {
      openComplexForm();
    }
  }, [patientPhoneNumber]);

  useEffect(() => {
    if (apptPatientId && practitionerId) {
      dispatch(
        serialActions.serial([
          () =>
            patientsActions.getOne(apptPatientId as string, {
              params: { centerId },
            }),

          () => pathname !== Routes.CREATE_APPOINTMENT && openComplexForm(),
        ]),
      );
    }
  }, [apptPatientId, practitionerId]);

  useEffect(() => {
    if (appointmentId && !complexFormData) {
      dispatch(
        serialActions.serial([
          () =>
            complexFormActions.getOne(appointmentId as string, {
              params: {
                aggregateWith:
                  'consultationReason,practitioner,patient,agendaSettings',
              },
            }),
          (state: RootState) =>
            patientsActions.setItem(state.complexForm.item?.patient || null),
          () => pathname !== Routes.CREATE_APPOINTMENT && openComplexForm(),
        ]),
      );
    }
  }, [appointmentId]);

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(30);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  useEffect(() => {
    const gtmId = config.get('GTM_ID');
    if (userId && gtmId) {
      ReactGA.gtag('config', gtmId, {
        user_id: userId,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      setScreebIdentity(dispatch, keycloak?.subject);
    }
  }, [userId, keycloak]);

  const sideBar = useMemo(
    () =>
      !isPathWithSidebar(pathname) && !isTabWithSidebar(tab) ? null : (
        <SideBar pathname={pathname} tab={tab} query={query} />
      ),
    [pathname, tab, isPractitioner, query],
  );

  if (!isUp)
    return (
      <>
        <SocketSuscribers token={token} />
        <MaintenancePage />
      </>
    );

  const page = snakeCase(pathname.slice(1)) || 'home';
  const variantSideBar = sideBar ? 'WithSideBar' : '';

  return (
    <>
      <NextSeo noindex nofollow />
      <DatacyContext.Provider value={`${page}_page`}>
        {pathname !== Routes.SIGNUP_LGC && <SocketSuscribers token={token} />}
        {userId && (
          <>
            <DynatraceDebugToken userId={userId} />
            <DialogMaster />
            {canDisplayWelcomeDialog && (
              <AutoOnboardingWelcomeDialog onClose={handleCloseWelcomeDialog} />
            )}
            <ConfirmLogoutDialog />
            <NotificationsListener />
            <DocumentOverlay />
            <VideoSessionPendingDialog />
            <TlcReminderDialog />
            <AutomaticTlcSuggestionDialog />
            <TlcErrorsDialog />
            <TlcDeactivateDialog />
            <PaymentErrorsDialog />
            <WatchPingPatient />
            <SnackBuildContainer />
            <ClearAccessEndDateDialog />
            <FusionDialogContainer />
            <MiniVideoSessionPendingDialog />
            <ExportStatisticsDocumentHandler />
            <PrivacyAndTermsUpdateModal />
          </>
        )}

        {/* TODO: ADD WRAPPER FUSIONPATIENTDIALOG no props */}
        <ConsultationReasonsTimeslotRefContextProvider>
          <Layout
            pathname={pathname}
            variantSideBar={variantSideBar}
            hideHeader={hideHeader(pathname)}
            header={isFreemium ? <HeaderIsFreemium /> : <Header />}
            sideBar={sideBar}
            content={children}
          />
        </ConsultationReasonsTimeslotRefContextProvider>
      </DatacyContext.Provider>
    </>
  );
};
// LayoutWrapper.whyDidYouRender = true;

// export default LayoutWrapper;
export default securedPage(LayoutWrapper);
