/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Tanstack Query Version : `4`
 *
 * Implementation for `proChatMessagesCountUnreadMessages` hook
 * Endpoint: `/pro/chat-messages/count-unread-messages`
 */

import { useQueryClient, useQuery } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

const queryParamsList = ["centerId"];

export function makeQueryKey(params) {
	const queryParams = params
		? omitBy(pick(params, queryParamsList), (o) => o === "" || o === null)
		: null;

	return [
		"@@api-pro",
		"pro",
		"chat-messages",
		"count-unread-messages",
		queryParams,
	];
}

export function useInvalidateQuery() {
	const queryClient = useQueryClient();
	return function invalidateQuery(params) {
		return queryClient.invalidateQueries({
			queryKey: makeQueryKey(params).filter(Boolean),
		});
	};
}

export function useGet(params, options) {
	return useQuery({
		...options,
		queryKey: makeQueryKey(params),
	});
}
