import React from 'react';
import CloseMui from '@mui/icons-material/Close';
import { alpha, createTheme as createMuiTheme } from '@mui/material/styles';
import deepmerge from 'deepmerge';

import colors from './colors';
import {
  AGENDA_MENU_HEIGHT,
  CHAT_HEADER_HEIGHT,
  HEADER_HEIGHT,
  MINICALENDAR_HEIGHT,
  PAGE_PADDING,
  PATIENTTOOLBAR_HEIGHT,
  SIDEBAR_CENTER_TREE_HEIGHT,
  SIDEBAR_WIDTH,
  TAB_HEIGHT,
} from './height';
import maiiaThemeOptions from './maiiaThemeOptions';

const createTheme = (initialValue = { headerHeight: HEADER_HEIGHT }) => {
  const { headerHeight } = initialValue;
  /*

Les variables qui forment la base de tout: Colors, spaces....

 _______   ______   .__   __.  _______       ___   .___________. __    ______   .__   __.
|   ____| /  __  \  |  \ |  | |       \     /   \  |           ||  |  /  __  \  |  \ |  |
|  |__   |  |  |  | |   \|  | |  .--.  |   /  ^  \ `---|  |----`|  | |  |  |  | |   \|  |
|   __|  |  |  |  | |  . `  | |  |  |  |  /  /_\  \    |  |     |  | |  |  |  | |  . `  |
|  |     |  `--'  | |  |\   | |  '--'  | /  _____  \   |  |     |  | |  `--'  | |  |\   |
|__|      \______/  |__| \__| |_______/ /__/     \__\  |__|     |__|  \______/  |__| \__|

*/
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 1024,
        sm: 1280,
        md: 1366,
        lg: 1920,
        xl: 2560,
        editPatientPage: 1648,
        tlcDrawer: 1240,
      },
    },
    palette: {
      logo: {
        dark: '#10748E',
        grey: '#AEC7D0',
        green: '#08A6A0',
      },
      gradient: 'linear-gradient(45deg, #64C9C3, #50B3C8)',
      primary: {
        xlight: '#FDE2E2',
        light: 'hsl(350, 86%, 80%)',
        main: '#fb3b7c',
        dark: '#D41153',
      },
      secondary: {
        xxlight: '#E0EFEE',
        xlight: '#E4F6F5',
        light: '#08A6A0',
        main: '#08A6A0',
        main2: '#009DC6',
        dark: '#55ABA6',
        backgroundNoOpacity: '#F5FBFA',
        boringLight: '#E5F4F7',
        calendarBackground: '#F9FCFC',
        chatMessageHighlight: '#E0F4F3',
      },
      main: {
        xxlight: '#E0EFEE',
        xlight: '#E4F6F5',
        light: '#08A6A0',
        main: '#08A6A0',
        main2: '#009DC6',
        dark: '#55ABA6',
        background: 'rgba(224, 239, 238, 0.3)',
        boringLight: '#E5F4F7',
        calendarBackground: '#F9FCFC',
        rdvtooltip: '#DBF3FA',
      },
      success: {
        xxlight: colors.green[100],
        xlight: colors.green[200],
        light: colors.green[400],
        main: colors.green[600],
        dark: colors.green[800],
      },
      info: {
        xxlight: colors.blue[100],
        xlight: colors.blue[200],
        light: colors.blue[400],
        main: colors.blue[600],
        dark: colors.blue[800],
      },
      warning: {
        xxlight: colors.orange[100],
        xlight: colors.orange[200],
        light: colors.orange[400],
        main: colors.orange[600],
        dark: colors.orange[800],
      },
      error: {
        xxlight: colors.red[100],
        xlight: colors.red[200],
        light: colors.red[400],
        main: colors.red[600],
        dark: colors.red[800],
      },
      accent: {
        xxlight: '#FEF2F4',
        xlight: '#FDE2E2',
        main: '#fb3b7c',
        dark: '#D41153',
      },
      destructive: {
        xxlight: colors.red[100],
        xlight: colors.red[200],
        light: colors.red[400],
        main: '#E51515',
        dark: colors.red[800],
      },
      disabled: {
        text: 'rgba(0, 0, 0, 0.26)',
        background: 'rgba(0, 0, 0, 0.12)',
        light: colors.grey[400],
        main: colors.grey[600],
        dark: colors.grey[800],
      },
      treatment: {
        light: '#F0F9FF',
        main: '#9EDAEB',
        hover: '#9EDAEB',
      },
      common: {
        black: '#20363D',
        white: '#FFFFFF',
        body: '#20363D',
        body2: '#FF00FF', // a supprimer
        inputBorder: '#A0AEC0',
        inputPlaceholder: '#578FA2',
        lightGray: '#DCDFE2',
      },
      text: {
        // valeurs de material ui, à override
        black: '#20363D',
        primary: '#20363D',
        secondary: '#578FA2',
        body: '#20363D',
        white: '#FFFFFF',
        disabled: '#578FA2',
        // hint: '#10748E'
        grey: '#20363D',
      },
      honouredStats: {
        tlc: '#ABE3E1',
        direct: '#32C3BD',
        physical: '#08A6A0',
        homeVisit: '#045350',
      },
      nonHonouredStats: {
        tlc: '#FAC7D8',
        direct: '#FC83AB',
        physical: '#FB3B7C',
        homeVisit: '#AA0E42',
      },
      ...colors,
    },
    typography: {
      fontFamily: ['"Source Sans 3"'].join(','),
    },
    size: {
      font: {
        xxs: '10px',
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '22px',
        '3xl': '26px',
        '4xl': '34px',
        logo: '68px',
      },
      spacing: {
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '24px',
        6: '32px',
        7: '48px',
        8: '64px',
        9: '96px',
        10: '128px',
        11: '192px',
        12: '256px',
        13: '384px',
        14: '512px',
        15: '640px',
        16: '768px',
        17: '896px',
      },
    },
    shape: {
      borderRadiusInput: 2,
      borderRadius: 4,
      borderRadiusLarge: 8,
      round: {
        height: 'var(--size)',
        width: 'var(--size)',
        borderRadius: '50%',
      },
      boxShadow: {
        xlight: '#E4F6F5',
      },
    },
    layout: {
      patients: {
        centerBannerHeight: '48px',
        patientSearchHeight: '39px',
        patientHeaderHeight: '43px',
        patientPaginationHeight: '52px',
        patientPadding: '104px',
      },
      headerHeight,
      headerHeightPx: `${headerHeight}px`,
      chatHeaderHeight: `${CHAT_HEADER_HEIGHT}px`,
      minicalendarHeight: MINICALENDAR_HEIGHT,
      patientToolbarHeight: PATIENTTOOLBAR_HEIGHT,
      pagePadding: `${PAGE_PADDING}px`,
      sidebarWidth: `${SIDEBAR_WIDTH}px`,
      chatThreadbarWidth: '400px',
      tabHeight: `${TAB_HEIGHT}px`,
      agendaHeaderHeight: `${AGENDA_MENU_HEIGHT}px`,
      grid: {
        teleExpertise: {
          display: 'grid',
          gridTemplateAreas:
            "'startdate applicant_name expert pat_file status conversation report'", // eslint-disable-line no-alert, quotes
          gridTemplateColumns:
            'minmax(65px, 130px) repeat(6, minmax(150px, 1fr))',
          gridGap: '1vw',
          minWidth: 1230,
          paddingLeft: 32,
          '& > [gridkey="startdate"]': { gridArea: 'startdate' },
          '& > [gridkey="applicant_name"]': { gridArea: 'applicant_name' },
          '& > [gridkey="expert"]': { gridArea: 'expert' },
          '& > [gridkey="pat_file"]': { gridArea: 'pat_file' },
          '& > [gridkey="status"]': { gridArea: 'status' },
          '& > [gridkey="conversation"], & [gridkey="report"]': {
            textAlign: 'center',
            width: '100%',
          },
        },
        patientInformation: {
          display: 'grid',
          gridTemplateColumns:
            '[checkbox] 32px [name] minmax(200px, 1fr) [merge-icon] 54px [sync] 112px [lock-icon] 24px [star-icon] 24px [internet-icon] 24px [birthdate] 85px [health-id] 143px [mobile] 171px [email] 300px',
          justifyItems: 'start',
          alignItems: 'start',
          columnGap: '16px',
          padding: '8px',
          '& [gridkey="patient-header-item"], [gridkey="patient-body-item"]': {
            padding: '8px',
            width: '100%',
            gridColumn: '1 / -1',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            '& > *': {
              whiteSpace: 'nowrap',
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
            '& > [gridkey="checkbox"]': {
              gridColumn: 'checkbox',
            },
            '& > [gridkey="name"]': {
              gridColumn: 'name',
            },
            '& > [gridkey="merge-icon"]': {
              gridColumn: 'merge-icon',
              display: 'flex',
              justifyContent: 'center',
            },
            '& > [gridkey="sync"]': {
              gridColumn: 'sync',
            },
            '& > [gridkey="lock-icon"]': {
              gridColumn: 'lock-icon',
            },
            '& > [gridkey="star-icon"]': {
              gridColumn: 'star-icon',
            },
            '& > [gridkey="internet-icon"]': {
              gridColumn: 'internet-icon',
            },
            '& > [gridkey="birthdate"]': {
              gridColumn: 'birthdate',
            },
            '& > [gridkey="health-id"]': {
              gridColumn: 'health-id',
            },
            '& > [gridkey="mobile"]': {
              gridColumn: 'mobile',
            },
            '& > [gridkey="email"]': {
              gridColumn: 'email',
            },
          },
        },
      },
    },
    transition: {
      cubic: 'cubic-bezier(0.81,-0.12, 0.64, 0.99)',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    zIndex: {
      // All accross the app zIndex
      chatHeaderIcon: 1,
      drawerArrow: 1,
      documentOverlay: 2,
      listComponent: 2,
      rdvListHeader: 2,
      fab: 4,
      chromeLikeTabs: 10,
      layoutHeader: 46,
      addButton: 50,
      layoutSidebar: 50,
      actionHistoryHeader: 50,
      prescription: 51,
      speedDial: 201,
      drawer: 500,
      tleFullscreen: 500,
      headerSearch: 501,
      complexRdv: 900,
      documentPreview: 901,
      menu: 999,
      appBar: 1100,
      miniOpenTok: 1200,
      modal: 1600,
      inputDatePicker: 1301, // for inputs in modal
      snackbar: 1600,
      tooltip: 1700,
      god: 9999,

      // Calendar page
      bigCalendarDragPreview: 4,
      calendarEventWrapper: 4,
      calendarAbsenceEventUnselected: 4,
      calendarRbcEventsContainer: 9,
      calendarRbcTimeSlotGroup: 10,
      calendarRbcEvent: 11,
      timeIndicator: 12,
      daysHeader: 20,
      tlcEmptyState: 20,
      popperFade: 1600,
      eventPlusOne: 81,
      pasteEventView: 90,
      bigCalendarStopCopyButton: 1050,

      // chat page
      groupOptionDrawer: 100,
      chatTooltip: 101,
      emojiPopover: 1501, // popover +1

      // login page
      loginPageJoinUs: 1,
      loginPageInput: 3,

      // Motifs page
      motifsHeader: 1,

      // profile page
      editableAvatarButton: 1,
      profilePageIframe: 50,
      profilePageLoader: 51,

      // ressources page
      ressourcesHeader: 1,

      // autoOnboarding page
      sliderArrow: 10,

      // TLC and video pro to pro page
      tlcNetworkLost: 50,
      tlcDisplayName: 900,
      tlcInfos: 1000,
      tlcMiniActions: 3000,

      // transactions page
      transactionHeaderItem: 1,
      transactionListNavigation: 2,
    },
    animation: {
      duration: {
        none: '0ms',
        extraExtraShortIn: '100ms',
        extraExtraShortOut: '100ms',
        extraShortIn: '200ms',
        extraShortOut: '150ms',
        shortIn: '280ms',
        shortOut: '200ms',
        mediumIn: '400ms',
        mediumOut: '350ms',
        longIn: '500ms',
        longOut: '350ms',
        extraLongIn: '1000ms',
        extraLongOut: '1000ms',
      },
      timingFunction: {
        enterExitIn: 'cubic-bezier(0.14, 1, 0.34, 1)',
        enterExitOut: 'cubic-bezier(0.45, 0.1, 0.2, 1)',
        swapShuffleIn: 'cubic-bezier(0.14, 1, 0.34, 1)',
        swapShuffleOut: 'cubic-bezier(0.45, 0.1, 0.2, 1)',
        moveIn: 'cubic-bezier(0.17, 0.17, 0, 1)',
        moveOut: 'cubic-bezier(0.17, 0.17, 0, 1)',
        expandCollapseIn: 'cubic-bezier(0.17, 0.17, 0, 1)',
        expandCollapseOut: 'cubic-bezier(0.17, 0.17, 0, 1)',
        passiveMoveIn: 'cubic-bezier(0.5, 0, 0.1, 1)',
        passiveMoveOut: 'cubic-bezier(0.5, 0, 0.1, 1)',
        quickMoveIn: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
        quickMoveOut: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
        fadeIn: 'cubic-bezier(0, 0, 1, 1)',
        fadeOut: 'cubic-bezier(0, 0, 1, 1)',
      },
      keyframes: {},
    },
  });

  /*

2. Les variables basées sur 1. qu'on pourra étendre plus tard, notamment
- les typography de base, par exemple title qui sera plus tard etendu en formTitle, tableTitle
- les couleurs d'actions, comme destructive, disabled, ko, selected, ok, whatever

  _______  _______ .__   __.  _______ .______       __    ______      __    __   _______
 /  _____||   ____||  \ |  | |   ____||   _  \     |  |  /  __  \    |  |  |  | |   ____|
|  |  __  |  |__   |   \|  | |  |__   |  |_)  |    |  | |  |  |  |   |  |  |  | |  |__
|  | |_ | |   __|  |  . `  | |   __|  |      /     |  | |  |  |  |   |  |  |  | |   __|
|  |__| | |  |____ |  |\   | |  |____ |  |\  \----.|  | |  `--'  '--.|  `--'  | |  |____
 \______| |_______||__| \__| |_______|| _| `._____||__|  \_____\_____\\______/  |_______|

*/

  const muiTheme = { ...theme };

  const genericTheme = {
    ...maiiaThemeOptions,
    palette: {
      ...maiiaThemeOptions.palette,
      charte: {
        // à supprimer
        gradient: 'linear-gradient(45deg, #64C9C3, #50B3C8)', // à supprimer
        red: {
          // à supprimer
          main: '#FF00FF', // à supprimer
          dark: '#FF00FF', // à supprimer
          darkest: '#FF00FF', // à supprimer
        }, // à supprimer
        green: '#FF00FF', // à supprimer
        background: '#FF00FF', // à supprimer
        grey: {
          // à supprimer
          light: '#FF00FF', // à supprimer
          main: '#FF00FF', // à supprimer
          dark: '#FF00FF', // à supprimer
        }, // à supprimer
      }, // à supprimer

      action: {
        // active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.08)',
        // hoverOpacity: 0.08,
        // selected: 'rgba(0, 0, 0, 0.14)',
        disabled: muiTheme.palette.grey[300],
        // disabledBackground: 'rgba(0, 0, 0, 0.12)',
        ...maiiaThemeOptions.palette.action,
      },
      text: {
        main: muiTheme.palette.main.main,
        accent: muiTheme.palette.accent.main,
        ...maiiaThemeOptions.palette.text,
      },
      background: {
        paper: '#FFFFFF',
        transparentWhite50: 'rgba(255, 255, 255, 0.5)',
        transparentWhite80: 'rgba(255, 255, 255, 0.8)',
        lightGrey: '#F6F6F6',
        tagGrey: '#D3D3D3',
        defaultGrey: '#F1F4F5',
        darkGrey: '#DCDCDC',
        darkerGrey: '#B7B7B7',
        substituteEvent: colors.trueGrey[500],
        substituteTimeSlot: colors.trueGrey[600],
        headerIconButton: alpha('#F1F4F5', 0.3),
        profilePageForm: alpha('#F1F4F5', 0.35),
        inputIconsDivider: '#AEC7D0',
        defaultAvatarBackground: '#F5F8F9',
        inputGrey: '#F1F4F5',
        rowOdd: '#F6FAFA',
        rowHover: '#BEE9E8',
        default: muiTheme.palette.main.background,
        notselected: {
          backgroundColor: muiTheme.palette.background.paper,
          borderColor: 'transparent',
          border: '1px solid',
        },
        selected: {
          backgroundColor: '#DBF3FA',
          borderColor: '#9EDAEB',
          border: '1px solid',
        },
        hovered: muiTheme.palette.grey[200],
        transparent: 'transparent',
        info: '#EDF7FA',
      },
    },

    typography: {
      link: {
        fontFamily: '"Source Sans 3"',
        fontSize: muiTheme.size.font.md,
        fontWeight: 500,
        color: muiTheme.palette.main.main,
        cursor: 'pointer',
        '&:hover': {
          color: muiTheme.palette.main.dark,
        },
      },

      title: {
        fontSize: muiTheme.size.font.lg,
        fontFamily: 'Outfit',
        fontWeight: 'bold',
        color: muiTheme.palette.common.body,
        letterSpacing: 0,
      },
      subtitle: {
        fontSize: muiTheme.size.font.md,
        fontFamily: 'Outfit',
        fontWeight: 'bold',
        color: muiTheme.palette.common.body,
        letterSpacing: 0,
      },
      body: {
        fontSize: muiTheme.size.font.md,
        fontFamily: '"Source Sans 3"',
        color: muiTheme.palette.common.body,
      },
      bodySmall: {
        fontSize: muiTheme.size.font.sm,
        fontFamily: '"Source Sans 3"',
        color: muiTheme.palette.common.body,
      },
      placeholder: {
        fontSize: muiTheme.size.font.sm,
        fontFamily: '"Source Sans 3"',
        fontStyle: 'italic',
        letterSpacing: 0,
      },
      inputText: {
        fontSize: muiTheme.size.font.sm,
        fontFamily: '"Source Sans 3"',
      },
      ...maiiaThemeOptions.typography,
    },

    layout: {
      ...muiTheme.layout,
      complexRdvheight: `calc(100vh - ${muiTheme.layout.headerHeight}px)`,
      documentPreview: `calc(100vh - ${muiTheme.layout.headerHeight}px)`,
      groupOptionDrawerHeight: `calc(100vh - ${muiTheme.layout.headerHeight}px)`,
      sidebarPatientListHeight: `calc(100vh - ${muiTheme.layout.headerHeight}px - ${SIDEBAR_CENTER_TREE_HEIGHT}px - 70px)`,
      sidebarChat: `calc(100vh - ${muiTheme.layout.headerHeight}px)`,
      topHeight: muiTheme.layout.headerHeight - PAGE_PADDING,
      tlc: {
        gap: muiTheme.size.spacing[5],
      },
    },
  };

  const mergedGenericTheme = deepmerge(muiTheme, genericTheme);

  /*

3. Les variables qui spécialisent un élément générique du theme

     _______..______    _______   ______  __       ___       __       __       _______.     ___   .___________. __    ______   .__   __.
    /       ||   _  \  |   ____| /      ||  |     /   \     |  |     |  |     /       |    /   \  |           ||  |  /  __  \  |  \ |  |
   |   (----`|  |_)  | |  |__   |  ,----'|  |    /  ^  \    |  |     |  |    |   (----`   /  ^  \ `---|  |----`|  | |  |  |  | |   \|  |
    \   \    |   ___/  |   __|  |  |     |  |   /  /_\  \   |  |     |  |     \   \      /  /_\  \    |  |     |  | |  |  |  | |  . `  |
.----)   |   |  |      |  |____ |  `----.|  |  /  _____  \  |  `----.|  | .----)   |    /  _____  \   |  |     |  | |  `--'  | |  |\   |
|_______/    | _|      |_______| \______||__| /__/     \__\ |_______||__| |_______/    /__/     \__\  |__|     |__|  \______/  |__| \__|

*/

  const specialTheme = {
    typography: {
      formBigTitle: {},
      formNameTitle: {
        ...mergedGenericTheme.typography.title,
      },
      formLabelTitle: {
        ...mergedGenericTheme.typography.body,
        // fontSize: mergedGenericTheme.size.font.md,
      },
      formTitle: {
        ...mergedGenericTheme.typography.title,
        color: mergedGenericTheme.palette.main.main,
      },
      formBody: {
        ...mergedGenericTheme.typography.body,
      },
      blueLink: {
        fontFamily: '"Source Sans 3"',
        cursor: 'pointer',
        color: mergedGenericTheme.palette.blue.main,
        '&:hover': {
          color: mergedGenericTheme.palette.blue.dark,
        },
      },

      /**
       * Table mergedGenericTheme
       */
      tableBody: {
        ...mergedGenericTheme.typography.body,
      },
      tableTitle: {
        ...mergedGenericTheme.typography.title,
        color: mergedGenericTheme.palette.main.main,
        fontSize: mergedGenericTheme.size.font.md,
      },
      groupLabel: {
        textTransform: 'none',
        fontSize: mergedGenericTheme.size.font.lg,
        fontFamily: '"Source Sans 3"',
        letterSpacing: 0,
        opacity: 1,
        fontWeight: 'bold',
        color: mergedGenericTheme.palette.common.black,
      },

      /**
       * event & absence
       */
      absenceBody: {
        fontSize: mergedGenericTheme.size.font.sm,
        fontFamily: '"Source Sans 3"',
        lineHeight: 1,
      },

      /**
       * list mergedGenericTheme
       */
      listItemBody: {
        ...mergedGenericTheme.typography.body,
      },
    },

    pages: {
      onboarding: {
        '& .header': {
          background: mergedGenericTheme.palette.charte.gradient,
          height: mergedGenericTheme.layout.headerHeight,
          display: 'flex',
          alignItems: 'center',
          '& .logo': {
            maxHeight: 30,
            color: mergedGenericTheme.palette.text.white,
            fontSize: mergedGenericTheme.size.font.logo,
            marginLeft: mergedGenericTheme.size.spacing[4],
          },
        },
        '& .main': {
          display: 'flex',
          height: `calc(100vh - ${mergedGenericTheme.layout.headerHeightPx})`,
        },

        leftlogin: {
          backgroundColor: mergedGenericTheme.palette.common.white,
          height: `calc(100vh - ${muiTheme.layout.headerHeight}px ) `,
          overflow: 'auto',
          flexBasis: '450px',
          flexShrink: 0,
          padding: mergedGenericTheme.spacing(7),
          paddingTop: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          boxShadow:
            '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
          paddingBottom: '12vh',
          '& h3': {
            fontSize: mergedGenericTheme.size.font['3xl'],
            marginBottom: mergedGenericTheme.spacing(4),
          },
        },
        '& .illustration': {
          background: `url('/static/images/agenda-medecin.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          margin: '100px',
          backgroundPosition: 'center',
          flexGrow: 1,
        },
      },
      loginPasswordReset: {
        leftForm: {
          backgroundColor: mergedGenericTheme.palette.common.white,
          width: '535px',
          padding: theme.size.spacing[7],
          paddingBottom: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          boxShadow:
            '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',

          '& .errorMessage': {
            color: mergedGenericTheme.palette.destructive.main,
          },
          '& .succesMessage': {
            color: mergedGenericTheme.palette.success.main,
          },

          '& .logo': {
            color: mergedGenericTheme.palette.main.main,
            width: 128,
            height: 'auto',
          },

          '& h2': {
            ...mergedGenericTheme.typography.title,
            color: mergedGenericTheme.palette.main.main,
            fontSize: mergedGenericTheme.size.font.xl,
            fontWeight: 'bold',
            marginTop: mergedGenericTheme.spacing(3),
            marginBottom: mergedGenericTheme.spacing(6),
          },

          '& div.form': {
            flexGrow: 1,
            marginTop: '15vh',

            '& div[gridkey="username"], & div[gridkey="password"]': {
              '& > div': {
                paddingLeft: 0,
                paddingRight: 0,
                flexWrap: 'nowrap',
                '& > div': { zIndex: mergedGenericTheme.zIndex.loginPageInput },
              },
            },
            '& .MuiInputAdornment-positionEnd': { cursor: 'pointer' },
            '& .btn': {
              marginTop: mergedGenericTheme.spacing(3),
            },
          },

          '& .wannajoin': {},
        },
        illustration: {
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          margin: '100px',
          backgroundPosition: 'center',
          flexGrow: 1,
        },
      },
      tlcPage: {
        tlcError: {
          position: 'absolute',
          top: mergedGenericTheme.layout.tlc.gap,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 520,
          zIndex: mergedGenericTheme.zIndex.tlcNetworkLost,
          borderRadius: 'var(--brds)',
          padding: '10px 30px',
          fontSize: mergedGenericTheme.size.font['2xl'],
        },

        tlcPillButtons: {
          backgroundColor: 'rgba(68, 77, 86, 0.7)',
          borderRadius: 30,
          '--paddingY': '5px',
          '--paddingX': '20px',
          '--size': '35px',
          padding: ' var(--paddingY) var(--paddingX)',
        },

        tlcButton: {
          padding: 'var(--paddingY)',
          '--btnSize': 'calc(var(--paddingY) * 2 + var(--size))',
          height: 'var(--btnSize)',
          width: 'var(--btnSize)',
          borderRadius: '30%',
          display: 'grid',
          cursor: 'pointer',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',

          '&.active, &.off': {
            '&::after': {
              content: "''",
              position: 'absolute',
              height: '4px',
              bottom: 'calc( -1 * var(--paddingY) )',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '70%',
              backgroundColor: 'white',
              '--size': '10px',
              borderTopLeftRadius: 'var(--size)',
              borderTopRightRadius: 'var(--size)',
            },
          },

          '&:hover': { backgroundColor: 'rgba(68, 77, 86, 1)' },

          '& svg': {
            // height: 'var(--size)',
            // width: 'var(--size)',
            fill: 'white',
          },
        },
      },
    },
    components: {
      header: {
        search: {
          backgroundColor: '#81CEE3',
        },
        gridTemplateColumns:
          '250px 1fr auto minmax(48px, 200px) minmax(400px, 500px) 48px minmax(50px, 1fr) 300px',
        demoChip: {
          backgroundColor: mergedGenericTheme.palette.primary.main,
          borderRadius: '5px',
          color: mergedGenericTheme.palette.common.white,
          '&.MuiChip-root': { marginRight: mergedGenericTheme.spacing(4) },
        },

        menu: {
          gridArea: 'menu',
          placeItems: 'center',
          '& button:not(.chatopen):not(.chatbtn):not(.upsell-btn)': {
            padding: 5,
          },
        },
      },

      dropDownWithImg: {
        '& > div:first-child': {
          maxHeight: mergedGenericTheme.size.spacing[10],
          overflowY: ' auto',
        },
        '& ul li': {
          '& img, & .initialsAvatar': {
            '--size': mergedGenericTheme.size.spacing[5],
            height: 'var(--size)',
            width: 'var(--size)',
            borderRadius: '50%',
            marginRight: mergedGenericTheme.size.spacing[3],
          },
        },
      },

      chatPage: {
        audioPlayer: {
          border: `1px solid ${mergedGenericTheme.palette.grey[300]}`,
          padding: '0 8px',
          borderRadius: mergedGenericTheme.size.spacing[2],
          '--placeholder-width': '200px',
          '--player-width': '250px',
        },
        hoverColor: mergedGenericTheme.palette.secondary.boringLight,
        tooltip: {
          backgroundColor: mergedGenericTheme.palette.common.white,
          border: `1px solid ${mergedGenericTheme.palette.grey[400]}`,
          borderRadius: mergedGenericTheme.size.spacing[1],
          boxShadow: mergedGenericTheme.shadows[2],
          zIndex: mergedGenericTheme.zIndex.chatTooltip,
        },
        input: {
          thread: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            '& .toolbarActions': {
              height: 50,
              padding: theme.spacing(1),
              marginLeft: 0,
              borderTop: `1px solid ${theme.palette.disabled.light}`,
              gridTemplateAreas: `'emoji patient document . cancel save'`,
              gridTemplateRows: 'unset',
              gridTemplateColumns:
                'repeat(3, var(--toolbar-btn-size)) 1fr 60px  var(--toolbar-btn-size)',
              '& .send': { gridArea: 'save' },
            },
          },
        },

        chatHeader: {
          height: 50,
          padding: 10,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: mergedGenericTheme.size.spacing[5],
          borderBottom: `1px solid ${mergedGenericTheme.palette.grey[400]}`,
          '& .dot': {
            height: mergedGenericTheme.size.spacing[2],
            width: mergedGenericTheme.size.spacing[2],
            borderRadius: '50%',
            marginRight: mergedGenericTheme.size.spacing[2],
            border: '1px solid',
            borderColor: 'transparent',
            '&.online': {
              '--online-color': mergedGenericTheme.palette.success.main,
              borderColor: 'var(--online-color)',
              backgroundColor: 'var(--online-color)',
            },
          },

          '& span': {
            ...theme.typography.body,
            fontWeight: 'bold',
            fontSize: 16,
          },
        },
      },

      simpleMenu: {
        '& .menuList': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: mergedGenericTheme.size.spacing[2],
          '--color-button-bg': 'transparent',
          '--color-button-bg--hover': '#CFDDE3',
          '--color-icon--hover': '#20363D',
          '--color-icon': '#20363D',
          '--color-separator': theme.palette.grey[500],

          '& + .menuList': {
            borderTop: '1px solid',
            borderColor: 'var(--color-separator)',
          },

          '& > button': {
            borderRadius: mergedGenericTheme.size.spacing[2],
            backgroundColor: 'var(--color-button-bg)',
            padding: mergedGenericTheme.size.spacing[2],
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& .label': { fontWeight: 500 },

            '& > svg:first-child, & > span.colorLetter': {
              height: 20,
              width: 20,
              marginRight: 10,
            },

            '& > svg:first-child': {
              color: 'var(--color-icon)',
            },
            '& > span.colorLetter:first-child': {
              backgroundColor: 'var(--color-icon)',
            },

            '&:hover': {
              '--color-icon': 'var(--color-icon--hover)',
              '--color-button-bg': 'var(--color-button-bg--hover)',
            },
          },
        },
      },

      patientLabel: {
        banned: {
          name: {
            textDecoration: 'line-through',
            color: mergedGenericTheme.palette.destructive.main,
          },
        },
      },

      chatMessage: {
        marginTop: mergedGenericTheme.size.spacing[3],
        paddingTop: 5,
        paddingBottom: 5,
        display: 'grid',
        paddingLeft: mergedGenericTheme.size.spacing[5],
        paddingRight: 100,
        gridTemplateAreas: `
    "avatar  name    name"
    "avatar  content content"
    ".       content content"
    ".       replies replies"
    `,
        gridTemplateColumns: '40px',
        gridTemplateRows: '20px 20px auto',
        gridColumnGap: mergedGenericTheme.size.spacing[3],
      },

      DropDownListStyle: {
        top: 30,
      },

      DropdownListItemStyle: {
        ...mergedGenericTheme.typography.body,
        backgroundColor: mergedGenericTheme.palette.background.defaultGrey,

        '&:hover': {
          fontWeight: 'bold',
        },
      },
      CornerButtonPosition: {
        bottom: theme.spacing(4),
        right: theme.spacing(6),
      },
      hoverableList: {
        '& > *:nth-child(odd)': {
          backgroundColor: mergedGenericTheme.palette.background.rowOdd,
        },
        '& > *:hover': {
          backgroundColor: mergedGenericTheme.palette.background.rowHover,
        },
      },
      rdvComplexHeader: {
        '& > * > *': {
          '&:not(:last-child)': {
            marginBottom: mergedGenericTheme.size.spacing[2],
          },
        },
      },
      rdvCard: {
        width: '500px',
        color: mergedGenericTheme.palette.common.body,
        fontSize: 16,
      },
      tlcSidebarListItem: {
        color: mergedGenericTheme.palette.text.body,
        borderBottom: `1px solid ${mergedGenericTheme.palette.disabled.light}`,
        '&:first-child': {
          borderTop: `1px solid ${mergedGenericTheme.palette.disabled.light}`,
        },
        '&:hover': {
          backgroundColor: mergedGenericTheme.palette.main.boringLight,
          transition: '0.2s ease',
        },
      },
      popup: {
        width: '600px',
        minHeight: '200px',
        color: mergedGenericTheme.palette.common.body,
        button: {
          fontSize: mergedGenericTheme.size.font.md,
        },
        '& input': {
          width: '100% !important',
        },
      },
      popupNoOverflow: {
        overflowY: 'auto',
        maxHeight: '50vh',
      },

      pillExclusiveFilter: {
        position: 'relative',
        '--label-width': '126px',
        '--color-background': mergedGenericTheme.palette.background.defaultGrey,
        '& > *': {
          display: 'flex',
          position: 'relative',
          backgroundColor: 'var(--color-background)',
          borderRadius: '99px',
          transition: 'all 0.15s ease-in',
          padding: '3px',
        },

        '& *': {
          zIndex: 2,
        },
        '& input[type="radio"]': {
          display: 'none',
        },
        '& input[type="radio"]:checked + label': {
          color: 'white',
          fontWeight: 500,
          transition: '0.25s ease-in-out',
        },
        '& input:first-of-type:checked ~ .glider': {
          transform: 'translatex(0)',
        },

        '& input:nth-of-type(2):checked ~ .glider': {
          transform: 'translatex(100%)',
        },

        '& input:nth-of-type(3):checked ~ .glider': {
          transform: 'translatex(200%)',
        },

        '& label': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 31,
          width: 'var(--label-width)',
          borderRadius: 99,
          cursor: 'pointer',
        },
        '& .glider': {
          position: 'absolute',
          display: 'flex',
          height: 31,

          width: 'calc(var(--label-width) + 1px)',
          backgroundColor: theme.palette.accent.main,
          borderRadius: '99px',
          transition: '0.25s ease-out',
          zIndex: 1,
        },
      },
      // can be sub specified (button, input, ...)
      linkButton: {
        color: mergedGenericTheme.palette.blue.main,
        textDecorationLine: 'none',
        '&:hover': {
          color: mergedGenericTheme.palette.blue.dark,
        },
      },
      selectedDayColor: {
        '--color': mergedGenericTheme.palette.accent.main,
      },
      movingUnderline: {
        // you can change the color by remapping the var --color-hover in your component
        '--color-hover': theme.palette.blue.dark,
        position: 'relative',
        '&:after': {
          width: '0%',
          transition: 'all 150ms ease',
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: 0,
          background: 'var(--color-hover)',
          height: '1px',
        },

        '&:hover': {
          color: 'var(--color-hover)',

          '&:after': {
            width: '100%',
          },
        },
      },
      skeleton: {
        color: 'transparent !important',
        fill: `${mergedGenericTheme.palette.action.hover} !important`,
        backgroundColor: `${mergedGenericTheme.palette.action.hover} !important`,
        animation: '$animate 1.5s ease-in-out infinite',
        pointerEvents: 'none',
        cursor: 'default',
      },

      dropdownListWrapper: {
        marginTop: mergedGenericTheme.spacing(2),
        maxHeight: '199px',
        overflowY: 'auto',
      },
      dropdownList: {
        width: '100%',
        zIndex: 1,
        // position: 'absolute',
        padding: 0,
        backgroundColor: mergedGenericTheme.palette.background.defaultGrey,
        borderWidth: 0,
        boxShadow: mergedGenericTheme.shadows[7],
      },
      dropdownListItem: {
        backgroundColor: `${mergedGenericTheme.palette.background.defaultGrey}!important`,
        // fontWeight: props.highlighted && 'bold',
        // color: props.selected && mergedGenericTheme.palette.accent.main,
      },
      errorWarningWrapper: {
        '&.error': {
          '--color-bg': mergedGenericTheme.palette.red[100],
          '--color-txt': mergedGenericTheme.palette.destructive.main,
          boxShadow:
            '0px 3px 3px -2px rgba(229,62,62,0.2),0px 3px 4px 0px rgba(229,62,62,0.14),0px 1px 8px 0px rgba(229,62,62,0.12)',
        },

        '&.warning': {
          '--color-bg': mergedGenericTheme.palette.orange[100],
          '--color-txt': mergedGenericTheme.palette.warning.main,
          boxShadow:
            '0px 3px 3px -2px rgba(221,107,32,0.2),0px 3px 4px 0px rgba(221,107,32,0.14),0px 1px 8px 0px rgba(221,107,32,0.12)',
        },
        fontFamily: '"Source Sans 3"',
        backgroundColor: 'var(--color-bg)',
        color: 'var(--color-txt)',
        '--width': '512px',
        width: 'var(--width)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${mergedGenericTheme.size.spacing[2]} ${mergedGenericTheme.size.spacing[4]}`,
        borderRadius: 10,
        // boxShadow: mergedGenericTheme.shadows[3],
        '& .bold': { fontWeight: 900 },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      tlc: {
        gap: mergedGenericTheme.size.spacing[5],
      },

      /* overrides */
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: mergedGenericTheme.palette.text.body,
            '& > .MuiChip-root': {
              // marginTop: 4,
              minHeight: 24,
              height: 'auto',
            },
            '&.Mui-disabled': {
              color: mergedGenericTheme.palette.text.body,
            },
          },
          input: {
            ...mergedGenericTheme.typography.inputText,
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            position: 'relative',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: mergedGenericTheme.palette.background.inputGrey,
            transition: 'unset',
            '&:hover': {
              // nothing happens on hover
              backgroundColor: mergedGenericTheme.palette.background.inputGrey,
            },
            '&.Mui-focused': {
              // nothing happens on focus for background
              backgroundColor: mergedGenericTheme.palette.background.inputGrey,
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
            },
            display: 'flex',
            flexWrap: 'wrap',
            '& > .MuiSelect-select': {
              lineHeight: '1.1875em', // Reset (19px), match the native input line-height https://github.com/mui-org/material-ui/blob/9379fa80781030cb6c4d0f545f7b08850f50b122/packages/material-ui/src/InputBase/InputBase.js#L37
            },
          },
          input: {
            padding: '8px 12px',
            '&::placeholder': {
              color: mergedGenericTheme.palette.common.inputPlaceholder,
              opacity: 1,
              fontStyle: 'italic',
            },
          },
          multiline: {
            padding: '10px 12px',
          },
          underline: {
            '&:after': {
              borderBottom: `2px solid ${mergedGenericTheme.palette.main.dark}`,
            },
            '&:before': {
              borderBottom: `1px solid transparent`,
            },
            '&.Mui-disabled:before': {
              borderBottom: `1px solid transparent !important`,
            },
            '&&:hover::before': {
              borderBottom: `1px solid transparent !important`,
            },
          },
        },
      },

      MuiCheckbox: {
        defaultProps: {
          root: {
            size: 'small',
            overflowY: 'auto',
            marginLeft: 30,
            marginRight: 30,
            boxShadow: mergedGenericTheme.shadows[2],
            backgroundColor: mergedGenericTheme.palette.common.white,
            borderTopLeftRadius: 0, // do not apply radius on the tab side
            borderRadius: mergedGenericTheme.shape.borderRadius,
          },
        },
        styleOverrides: {
          root: {
            color: 'inherit',
            '& * MuiSvgIcon-root': {
              color: 'inherit',
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: 'inherit',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '&.Mui-disabled': {
              color: mergedGenericTheme.palette.action.disabled,
            },
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: 'inherit',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '&.Mui-disabled': {
              color: mergedGenericTheme.palette.action.disabled,
            },
          },
        },
      },

      MuiSwitch: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          colorSecondary: {
            '&.Mui-checked': {
              color: mergedGenericTheme.palette.accent.main,
              '&:hover': {
                backgroundColor: alpha(
                  mergedGenericTheme.palette.accent.main,
                  mergedGenericTheme.palette.action.hoverOpacity,
                ),
              },
            },
            '&.Mui-disabled': {
              color: mergedGenericTheme.palette.action.disabled,
            },
            '&.Mui-checked + $track': {
              backgroundColor: mergedGenericTheme.palette.accent.main,
            },
            '&.Mui-disabled + $track': {
              // backgroundColor: mergedGenericTheme.palette.common.white,
            },
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: mergedGenericTheme.palette.secondary.main,
            '&:hover': {
              backgroundColor: alpha(
                mergedGenericTheme.palette.secondary.main,
                mergedGenericTheme.palette.action.hoverOpacity,
              ),
            },
          },
          colorSecondary: {
            '&.Mui-checked': {
              color: mergedGenericTheme.palette.secondary.main,
              '&:hover': {
                backgroundColor: alpha(
                  mergedGenericTheme.palette.secondary.main,
                  mergedGenericTheme.palette.action.hoverOpacity,
                ),
              },
            },
            '&.Mui-disabled': {
              color: mergedGenericTheme.palette.action.disabled,
            },
          },
        },
      },

      MuiChip: {
        defaultProps: {
          deleteIcon: React.createElement(CloseMui, null), // not using jsx because it is a .js file - it messes up jest when it's imported
        },
        styleOverrides: {
          outlined: {
            '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
              backgroundColor: alpha(
                mergedGenericTheme.palette.accent.main,
                mergedGenericTheme.palette.action.hoverOpacity,
              ),
            },
          },
          root: {
            color: mergedGenericTheme.palette.text.body,
            borderRadius: 0,
            height: mergedGenericTheme.size.spacing[5],
            margin: mergedGenericTheme.size.spacing[1],
          },
          deleteIcon: {
            color: mergedGenericTheme.palette.text.body,
            width: mergedGenericTheme.size.spacing[4],
            height: mergedGenericTheme.size.spacing[4],
            '&:hover': {
              color: mergedGenericTheme.palette.accent.main,
            },
          },
        },
      },

      // helper text under input (error and info state)
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: mergedGenericTheme.palette.error.main,
          },
          contained: {
            margin: mergedGenericTheme.size.spacing[1],
          },
        },
      },
      MuiFormControlLabel: {
        // checkbox and radio box
        styleOverrides: {
          root: {
            color: mergedGenericTheme.palette.text.body,
            '& .MuiSvgIcon-root': {
              fontSize: mergedGenericTheme.size.font.lg,
            },
          },

          // label next to checkbox and radio box
          label: {
            ...mergedGenericTheme.typography.inputText,
          },
        },
      },
      badgesContainer: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        marginLeft: 'auto',
        '& .connectBadge': {
          margin: `0 ${theme.spacing(2)}`,
        },
      },
    },

    /**
     * LAYOUT GLOBAL STYLE
     */
    layout: {
      sidebarWidth: SIDEBAR_WIDTH,
      regularPage: {
        minHeight: `calc(100vh - ${muiTheme.layout.headerHeight}px ) `,
      },
      rdvHistory: {
        display: 'grid',
        gridTemplateColumns: '180px 1fr 1fr 24px 36px',
        gridColumnGap: mergedGenericTheme.size.spacing[3],
        borderLeft: '5px solid',
      },
      patientContentWithoutGutter: {
        height: `calc(100vh
          - ${muiTheme.layout.headerHeight}px
          - ${PATIENTTOOLBAR_HEIGHT}px
          - ${TAB_HEIGHT}px + 2px) `,
      },
      paramsPage: {
        fontFamily: '"Source Sans 3"',
        height: `calc(100vh
          - ${muiTheme.layout.headerHeight}px
          - ${TAB_HEIGHT}px
          - ${PAGE_PADDING}px)`,
        borderRadius: 8,
        backgroundColor: mergedGenericTheme.palette.common.white,
      },
      box: {
        borderBottomLeftRadius: mergedGenericTheme.shape.borderRadiusLarge,
        borderBottomRightRadius: mergedGenericTheme.shape.borderRadiusLarge,
        backgroundColor: mergedGenericTheme.palette.common.white,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      patientContent: {
        height: `calc(100vh
          - ${muiTheme.layout.headerHeight}px
          - ${PATIENTTOOLBAR_HEIGHT}px
          - ${TAB_HEIGHT}px
          - ${3 * PAGE_PADDING}px)`,
        backgroundColor: mergedGenericTheme.palette.common.white,
      },
      tlc: {
        height: `calc(100vh
          - ${mergedGenericTheme.layout.headerHeight}px
          - ${theme.layout.headerHeight}px
          - ${mergedGenericTheme.layout.tlc.gap})`,
      },
      actionsHistory: {
        display: 'grid',
        gridTemplateAreas:
          "'type appointment-date practitioner consultation-reason patient modification-date user'", // eslint-disable-line no-alert, quotes
        gridTemplateColumns:
          '20px 165px minmax(130px, 1fr) minmax(150px, 1fr) minmax(130px, 1fr) 165px minmax(130px, 1fr)',
        gap: '2vw',
      },
      documentsHistory: {
        display: 'grid',
        gridTemplateAreas:
          "'read-status received-date patient category view read-select'",
        gridTemplateColumns: '25px 200px 1fr 150px 48px 100px',
        gap: '2vw',
        '& .received-date': { gridArea: 'received-date' },
      },
    },

    spacing: size => mergedGenericTheme.size.spacing[size],
    fontSize: size => mergedGenericTheme.size.font[size],
  };

  const finalTheme = deepmerge(mergedGenericTheme, specialTheme);
  return finalTheme;
};

export const muiTheme = createTheme();

export default createTheme;
