import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import { Store } from 'redux';

import { MaiiaNextPageContext } from '@/src/types/MaiiaNextContext';

export type RoutingContext = {
  ctx: {
    pathname: string;
    query: {
      practitionerId?: string;
      query?: string;
      centerId?: string;
      tab?: string;
      date?: string;
      oldCenterId?: string;
      rootCenterId?: string;
    };
    store: Store;
    req?: any;
    res?: any;
  };
};

function useSerialActionsAsRouter(
  serialActions: ({ ctx }: { ctx: MaiiaNextPageContext }) => void,
) {
  const router = useRouter();
  const store = useStore();

  function prepareArguments(url: string) {
    const urlInfos = new URL(`${window.location.origin}${url}`);
    return {
      pathname: urlInfos.pathname,
      query: Object.fromEntries(urlInfos.searchParams),
      store,
    } as MaiiaNextPageContext;
  }

  // // eslint-disable-next-line unused-imports/no-unused-vars-ts
  // function handleRouteChangeStart(_url: string) {
  //   /**
  //    * Add here any action to perform on each route change start.
  //    **
  //    * For now this function isn't used but may in a near future.
  //    * Until next 14/15 upgrade we want to keep this in case it becomes useful for getInitialProps removal.
  //    */
  // }

  function handleRouteChangeComplete(url: string) {
    serialActions({ ctx: prepareArguments(url) });
  }

  useEffect(() => {
    const initialUrl = `${window.location.pathname}${window.location.search}`;

    serialActions({ ctx: prepareArguments(initialUrl) });
    // router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      // router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);
}

export { useSerialActionsAsRouter };
