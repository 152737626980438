import { DurationInputArg2 } from 'moment';

import {
  AppointmentGetDTO,
  ConsultationReason,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  AnalyticsEhrActions,
  AnalyticsInstructionsActions,
  AnalyticsResourcesActions,
  AnalyticsResourcesLabels,
  AnalyticsSecretaryInstructionsActions,
  GAActions,
  GAAgendaSettingConsultationReasonActions,
  GAAgendaSettingConsultationReasonLabels,
  GAAgendaSettingPractitionerProfileActions,
  GAAgendaSettingPractitionerProfileLabels,
  GAAgendaViewActions,
  GAAgendaViewLabels,
  GAAppointmentsHistoryActions,
  GAAuthenticationActions,
  GACategories,
  GACopyPasteAgendaActions,
  GAEventCategoriesType,
  GALabels,
  GANoteActions,
  GANotificationSettingActions,
  GAOmnidocActions,
  GAPrintActions,
  GASideBarAgendaActions,
  GASideBarAgendaLabels,
  GATerminalInstructionSettingActions,
  GATlcLabels,
  GATlcSessionActions,
  GATlcSettingActions,
  GATlcSettingLabels,
  GATransactionActions,
  GATransactionLabels,
  GAUpsellActions,
  GAWaitingListActions,
} from './tracking';

export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const ACTIVE = 'ACTIVE';
export const DELETED = 'DELETED';
export const ALL = 'ALL';
export const CENTER = 'CENTER';
export const REFERING_PRACTITIONER = 'REFERING_PRACTITIONER';
export const PUBLIC = 'PUBLIC';
export const PRACTITIONER = 'PRACTITIONER';
export const UNKNOWN = 'UNKNOWN';
export const WEB_PRO = 'WEB_PRO';
export const WEB_PUBLIC = 'WEB_PUBLIC';
export const TLS = 'TLS';
export const APP_PRO = 'APP_PRO';
export const APP_PUBLIC = 'APP_PUBLIC';
export const WEB_TLC_RELAY = 'WEB_TLC_RELAY';
export const SYNCHRO = 'SYNCHRO';
export const WEB_BO = 'WEB_BO';
export const TINY = 'TINY';
export const SHORT = 'SHORT';
export const MEDIUM = 'MEDIUM';
export const LONG = 'LONG';
export const NONE = 'NONE';
export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const DELAYED = 'DELAYED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CANCELLED = 'CANCELLED';
export const COMPLETED = 'COMPLETED';
export const IN_ERROR = 'IN_ERROR';
export const NO_SHOW = 'NO_SHOW';
export const EXPIRED = 'EXPIRED';
export const BY_PATIENT = 'BY_PATIENT';
export const BY_PRACTITIONER_FOR_PATIENT = 'BY_PRACTITIONER_FOR_PATIENT';
export const BY_PRACTITIONER = 'BY_PRACTITIONER';
export const REPLACEMENT = 'REPLACEMENT';
export const PHYSICAL = 'PHYSICAL';
export const TELECONSULTATION = 'TELECONSULTATION';
export const DIRECT_TELECONSULTATION = 'DIRECT_TELECONSULTATION';
export const ABSENCE = 'ABSENCE';
export const OTHER = 'OTHER';
export const NOTE = 'NOTE';
export const EXPIRED_TELECONSULTATION = 'EXPIRED_TELECONSULTATION';
export const PNG = 'PNG';
export const JPEG = 'JPEG';
export const JPG = 'JPG';
export const GIF = 'GIF';
export const PDF = 'PDF';
export const OCTET_STREAM = 'OCTET_STREAM';
export const SECRETARY = 'SECRETARY';
export const TELESECRETARY = 'TELESECRETARY';
export const REGULATOR = 'REGULATOR';
export const ADMIN = 'ADMIN';
export const ADMIN_OPS = 'ADMIN_OPS';
export const QA = 'QA';
export const OPERATION = 'OPERATION';
export const SALE = 'SALE';
export const PRODUCT = 'PRODUCT';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const CEGEDIM_SUPPORT = 'CEGEDIM_SUPPORT';
export const PHARMACY = 'PHARMACY';
export const TO_ASSOCIATE = 'TO_ASSOCIATE';
export const ASSOCIATED = 'ASSOCIATED';
export const INITIALIZING = 'INITIALIZING';
export const SYNCHRONIZED = 'SYNCHRONIZED';
export const SUSPENDED = 'SUSPENDED';
export const BLOCKED = 'BLOCKED';
export const BASIC_SETTINGS = 'BASIC_SETTINGS';
export const AGENDA_MANAGEMENT = 'AGENDA_MANAGEMENT';
export const PATIENT_MANAGEMENT = 'PATIENT_MANAGEMENT';
export const AGENDA_SETTINGS = 'AGENDA_SETTINGS';
export const PROFILE_MANAGEMENT = 'PROFILE_MANAGEMENT';
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const PRIVATE = 'PRIVATE';
export const PHYSICAL_OR_TELECONSULTATION = 'PHYSICAL_OR_TELECONSULTATION';
export const MN = 'MN';
export const H = 'H';
export const D = 'D';
export const W = 'W';
export const M = 'M';
export const Y = 'Y';
export const MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE';
export const BIOLOGICAL_ANALYSIS = 'BIOLOGICAL_ANALYSIS';
export const REPORT = 'REPORT';
export const MEDICAL_IMAGING = 'MEDICAL_IMAGING';
export const PREVENTION = 'PREVENTION';
export const PRESCRIPTION = 'PRESCRIPTION';
export const SPECIALIZATION = 'SPECIALIZATION';
export const CONSULTATION_REASON = 'CONSULTATION_REASON';
export const SKILL = 'SKILL';
export const DISEASE = 'DISEASE';
export const AWAITING = 'AWAITING';
export const DONE = 'DONE';
export const ERROR = 'ERROR';
export const ENDED = 'ENDED';
export const ROLLING_BACK = 'ROLLING_BACK';
export const DATA_ERASED = 'DATA_ERASED';
export const IN_PROGRESS_PATIENTS = 'IN_PROGRESS_PATIENTS';
export const IN_PROGRESS_APPOINTMENTS = 'IN_PROGRESS_APPOINTMENTS';
export const DONE_PATIENTS = 'DONE_PATIENTS';
export const CITY = 'CITY';
export const DEPARTMENT = 'DEPARTMENT';
export const REGION = 'REGION';
export const DISTRICT = 'DISTRICT';
export const PRO = 'PRO';
export const PATIENT = 'PATIENT';
export const BACK_OFFICE = 'BACK_OFFICE';
export const PATIENT_ANONYMOUS = 'PATIENT_ANONYMOUS';
export const ANONYMOUS = 'ANONYMOUS';
export const EXTERNAL_SYNC = 'EXTERNAL_SYNC';
export const CALL_CENTER = 'CALL_CENTER';
export const DOCAVENUE_PATIENT = 'DOCAVENUE_PATIENT';
export const SERVICES = 'SERVICES';
export const TELEPHONE = 'TELEPHONE';
export const DOCAVENUE_PRO = 'DOCAVENUE_PRO';
export const APPLI_MOBILE_PATIENT = 'APPLI_MOBILE_PATIENT';
export const MLM_PS = 'MLM_PS';
export const WITHOUT_PAYMENT = 'WITHOUT_PAYMENT';
export const WAITING_PAYMENT = 'WAITING_PAYMENT';
export const WAITING_STRIPE = 'WAITING_STRIPE';
export const WAITING_STRIPE_ERROR = 'WAITING_STRIPE_ERROR';
export const PAYED = 'PAYED';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const EXTERNAL_ERROR = 'EXTERNAL_ERROR';
export const TO_CAPTURE = 'TO_CAPTURE';
export const PREAUTHORIZATION_KO = 'PREAUTHORIZATION_KO';
export const IMPORTING = 'IMPORTING';
export const TODO = 'TODO';
export const PRACTITIONERS = 'PRACTITIONERS';
export const PATIENTS = 'PATIENTS';
export const USERS = 'USERS';
export const CONSULTATION_REASONS = 'CONSULTATION_REASONS';
export const WAITING_LIST = 'WAITING_LIST';
export const APPOINTMENTS = 'APPOINTMENTS';
export const HOUR_HH_MM_SS_A = 'HOUR_HH_MM_SS_A';
export const HOUR_HH24_MM_SS = 'HOUR_HH24_MM_SS';
export const HOUR_HH_MM_A = 'HOUR_HH_MM_A';
export const HOUR_HH_MM = 'HOUR_HH_MM';
export const HOUR_HH_H_MM_MIN_SSS_A = 'HOUR_HH_H_MM_MIN_SSS_A';
export const HOUR_HH_H_MM_MIN_SSS = 'HOUR_HH_H_MM_MIN_SSS';
export const HOUR_HH_H_MM_MIN_A = 'HOUR_HH_H_MM_MIN_A';
export const HOUR_HH_H_MM_MIN = 'HOUR_HH_H_MM_MIN';
export const DATE_DD_MM_YYYY = 'DATE_DD_MM_YYYY';
export const DATE_DD_MM_YYYY_2 = 'DATE_DD_MM_YYYY_2';
export const DATE_YYYY_MM_DD = 'DATE_YYYY_MM_DD';
export const DATE_DD_MM_YY = 'DATE_DD_MM_YY';
export const DATE_EEEE_DD_MMMM_YYYY = 'DATE_EEEE_DD_MMMM_YYYY';
export const DATE_HOUR_DD_MM_YYYY = 'DATE_HOUR_DD_MM_YYYY';
export const DATE_HOUR_DD_MM_YYYY_2 = 'DATE_HOUR_DD_MM_YYYY_2';
export const DATE_HOUR_DD_MM_YYYY_HH_MM = 'DATE_HOUR_DD_MM_YYYY_HH_MM';
export const DATE_HOUR_DD_MM_YYYY_HH_MM_SS = 'DATE_HOUR_DD_MM_YYYY_HH_MM_SS';
export const DATE_HOUR_DD_MM_YY_HH_MM_SS = 'DATE_HOUR_DD_MM_YY_HH_MM_SS';
export const DATE_HOUR_DD_MM_YY = 'DATE_HOUR_DD_MM_YY';
export const DATE_HOUR_MM_DD_YY_HH_MM = 'DATE_HOUR_MM_DD_YY_HH_MM';
export const DATE_HOUR_MM_DD_YYYY_HH_MM_SS = 'DATE_HOUR_MM_DD_YYYY_HH_MM_SS';
export const DATE_HOUR_MM_DD_YYYY_HH_MM = 'DATE_HOUR_MM_DD_YYYY_HH_MM';
export const DATE_HOUR_MM_DD_YY_HH_MM_SS_2 = 'DATE_HOUR_MM_DD_YY_HH_MM_SS_2';
export const DATE_HOUR_MM_DD_YY_HH_MM_2 = 'DATE_HOUR_MM_DD_YY_HH_MM_2';
export const DATE_HOUR_MM_DD_YY_HH_MM_SS = 'DATE_HOUR_MM_DD_YY_HH_MM_SS';
export const DATE_HOUR_MM_DD_YYYY_HH_MM_2 = 'DATE_HOUR_MM_DD_YYYY_HH_MM_2';
export const DATE_HOUR_YYYY_MM_DD = 'DATE_HOUR_YYYY_MM_DD';
export const DATE_HOUR_YYYY_M_D_HHH_MM_SS = 'DATE_HOUR_YYYY_M_D_HHH_MM_SS';
export const DATE_HOUR_YYYY_M_D_T_HHH_MM_SS = 'DATE_HOUR_YYYY_M_D_T_HHH_MM_SS';
export const DATE_HOUR_EEEE_DD_MMMM_YYYY = 'DATE_HOUR_EEEE_DD_MMMM_YYYY';
export const DATE_HOUR_EEEE_D_MMMM_YYYY_HH_H_MM_Z =
  'DATE_HOUR_EEEE_D_MMMM_YYYY_HH_H_MM_Z';
export const PATIENT_ID = 'PATIENT_ID';
export const SPECIALITY_ID = 'SPECIALITY_ID';
export const START_DATE = 'START_DATE';
export const START_DATE_HOUR = 'START_DATE_HOUR';
export const START_DATE_TIME = 'START_DATE_TIME';
export const END_DATE = 'END_DATE';
export const DURATION = 'DURATION';
export const COMMENT = 'COMMENT';
export const ARRIVAL_DATE = 'ARRIVAL_DATE';
export const APPOINTMENT_AUTHOR = 'APPOINTMENT_AUTHOR';
export const CANCEL_DATE = 'CANCEL_DATE';
export const CANCEL_REASON = 'CANCEL_REASON';
export const PAYMENT_INFORMATION = 'PAYMENT_INFORMATION';
export const CONSENT_DATE = 'CONSENT_DATE';
export const IS_CMU = 'IS_CMU';
export const IS_WEB = 'IS_WEB';
export const PATIENT_LASTNAME = 'PATIENT_LASTNAME';
export const PATIENT_FIRSTNAME = 'PATIENT_FIRSTNAME';
export const PATIENT_BIRTHDATE = 'PATIENT_BIRTHDATE';
export const PATIENT_SEX = 'PATIENT_SEX';
export const PATIENT_BIRTHNAME = 'PATIENT_BIRTHNAME';
export const PATIENT_MOBILE_PHONE_NUMBER = 'PATIENT_MOBILE_PHONE_NUMBER';
export const PATIENT_PHONE_NUMBER = 'PATIENT_PHONE_NUMBER';
export const PATIENT_EMAIL = 'PATIENT_EMAIL';
export const PATIENT_ADDRESS_STREET = 'PATIENT_ADDRESS_STREET';
export const PATIENT_ADDRESS_ZIPCODE = 'PATIENT_ADDRESS_ZIPCODE';
export const PATIENT_ADDRESS_CITY = 'PATIENT_ADDRESS_CITY';
export const EXTERNAL_ID = 'EXTERNAL_ID';
export const SYNC_ID = 'SYNC_ID';
export const CREATION_DATE = 'CREATION_DATE';
export const FIRSTNAME = 'FIRSTNAME';
export const LASTNAME = 'LASTNAME';
export const BIRTHNAME = 'BIRTHNAME';
export const SEX = 'SEX';
export const BITHDATE = 'BITHDATE';
export const BIRTH_CITY = 'BIRTH_CITY';
export const HEALTH_ID = 'HEALTH_ID';
export const MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const EMAIL = 'EMAIL';
export const ADDRESS = 'ADDRESS';
export const ADDRESS_NUMBER = 'ADDRESS_NUMBER';
export const ADDRESS_STREET = 'ADDRESS_STREET';
export const ADDRESS_CITY = 'ADDRESS_CITY';
export const ADDRESS_ZIPCODE = 'ADDRESS_ZIPCODE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const DEACTIVATE = 'DEACTIVATE';
export const REACTIVATE = 'REACTIVATE';
export const RESET = 'RESET';
export const SPECIALITY = 'SPECIALITY';
export const EXPERTISE = 'EXPERTISE';
export const APPOINTMENT = 'APPOINTMENT';
export const DOCUMENT = 'DOCUMENT';
export const USER = 'USER';
export const VIDEO_SESSION = 'VIDEO_SESSION';
export const RESOURCE = 'RESOURCE';
export const SMS = 'SMS';
export const RECEIVED = 'RECEIVED';
export const SENT = 'SENT';
export const DISABLE = 'DISABLE';
export const READ_ONLY = 'READ_ONLY';
export const READ_WRITE = 'READ_WRITE';
export const MIGRATION = 'MIGRATION';
export const IMPORT = 'IMPORT';
export const F = 'F';
export const U = 'U';
export const SECTOR1 = 'SECTOR1';
export const SECTOR1_DP_OPTAM = 'SECTOR1_DP_OPTAM';
export const SECTOR2 = 'SECTOR2';
export const SECTOR2_OPTAM = 'SECTOR2_OPTAM';
export const SECTOR2_OPTAM_CO = 'SECTOR2_OPTAM_CO';
export const SECTOR3 = 'SECTOR3';
export const AFFILIATED = 'AFFILIATED';
export const NOT_AFFILIATED = 'NOT_AFFILIATED';
export const CB = 'CB';
export const CASH = 'CASH';
export const CHECK = 'CHECK';
export const THIRD_PARTY_PAYER = 'THIRD_PARTY_PAYER';
export const VITAL_CARD = 'VITAL_CARD';
export const NURSE = 'NURSE';
export const EHPAD = 'EHPAD';
export const TERMINATION = 'TERMINATION';
export const STARTED = 'STARTED';
export const WAITING = 'WAITING';
export const ACCEPTED = 'ACCEPTED';
export const CREATED = 'CREATED';
export const REFUSED = 'REFUSED';
export const FINISHED = 'FINISHED';
export const CRON_FINISHED = 'CRON_FINISHED';
export const PATIENT_FINISHED = 'PATIENT_FINISHED';
export const PATIENT_CANCELLED = 'PATIENT_CANCELLED';
export const PATIENT_EXPIRED = 'PATIENT_EXPIRED';
export const EVERY_1_WEEK = 'EVERY_1_WEEK';
export const EVERY_2_WEEK = 'EVERY_2_WEEK';
export const EVERY_3_WEEK = 'EVERY_3_WEEK';
export const EVERY_4_WEEK = 'EVERY_4_WEEK';
export const EVERY_5_WEEK = 'EVERY_5_WEEK';
export const EVERY_6_WEEK = 'EVERY_6_WEEK';
export const EVERY_7_WEEK = 'EVERY_7_WEEK';
export const EVERY_8_WEEK = 'EVERY_8_WEEK';
export const EXPIRED_JWT = 'EXPIRED_JWT';
export const MINIMUM_ADDRESS_ACCURACY = 'MINIMUM_ADDRESS_ACCURACY';
export const PI_DEMO = 'PI_DEMO';
export const REPLACE = 'REPLACE';
export const ONLINE_CEGEDIM = 'ONLINE_CEGEDIM';
export const ONLINE_CALLIBRI = 'ONLINE_CALLIBRI';
export const COMPLEMENTARY_PRESCRIPTION = 'COMPLEMENTARY_PRESCRIPTION';
export const EXPIRED_PAYMENT = 'EXPIRED_PAYMENT';
export const MISSED = 'MISSED';
export const SICK_LEAVE = 'SICK_LEAVE';
export const NOTIFICATION_CENTER_CANCELLED = 'CANCELLED';
export const NOTIFICATION_CENTER_MODIFIED = 'MODIFIED';
export const TLC_24_7 = 'TLC_24_7';
export const HOME_VISIT = 'HOME_VISIT';

export const languagesList = [
  'pt',
  'de',
  'en',
  'ar',
  'bn',
  'zh',
  'da',
  'es',
  'hi',
  'hu',
  'it',
  'jp',
  'nl',
  'no',
  'pl',
  'ro',
  'ru',
  'sv',
  'tr',
  'id',
  'ko',
  'vi',
  'bg',
  'hy',
  'he',
  'ht',
  'rc',
  'el',
];
export const PATIENT_ROW_COUNT_PER_PAGE = 25;
export const PATIENT_MENTION_SUGGESTIONS_LIMIT = 10;
export const genders = [
  { id: M, name: 'patientform_label_man', datacy: 'man' },
  { id: F, name: 'patientform_label_woman', datacy: 'woman' },
  { id: U, name: 'patientform_label_unkown', datacy: 'unkown' },
];

export const COLORS = [
  '#38B2AC',
  '#4FD1C5',
  '#81E6D9',
  '#4299E1',
  '#63B3ED',
  '#90CDF4',
  '#667EEA',
  '#7F9CF5',
  '#A3BFFA',
  '#9F7AEA',
  '#B794F4',
  '#D6BCFA',
  '#ED64A6',
  '#F687B3',
  '#FBB6CE',
  '#F56565',
  '#FC8181',
  '#FEB2B2',
  '#ED8936',
  '#F6AD55',
  '#FBD38D',
  '#ECC94B',
  '#F6E05E',
  '#FAF089',
  '#48BB78',
  '#68D391',
  '#9AE6B4',
];

export type PaymentMethod = {
  label: string;
  id: string;
  analyticsLabel?: string;
};
export type RefundsMethod = { label: string; id: string };

export const paymentMethods: PaymentMethod[] = [
  {
    label: 'payment_option_creditcard',
    id: CB,
    analyticsLabel: GAAgendaSettingPractitionerProfileLabels.BankCard,
  },
  {
    label: 'payment_option_check',
    id: CHECK,
    analyticsLabel: GAAgendaSettingPractitionerProfileLabels.Check,
  },
  {
    label: 'payment_option_cash',
    id: CASH,
    analyticsLabel: GAAgendaSettingPractitionerProfileLabels.Cash,
  },
];

export const refundMethods: RefundsMethod[] = [
  { label: 'refund_method_THIRD_PARTY_PAYER', id: THIRD_PARTY_PAYER },
  { label: 'refund_method_VITAL_CARD', id: VITAL_CARD },
];

export const documentLabelCategories = [
  { id: PRESCRIPTION, name: 'document_category_prescription_medicine' },
  {
    id: COMPLEMENTARY_PRESCRIPTION,
    name: 'document_category_prescription_complementary',
  },
  { id: BIOLOGICAL_ANALYSIS, name: 'documentcategory_biological_analysis' },
  { id: MEDICAL_CERTIFICATE, name: 'documentcategory_medical_certificate' },
  { id: SICK_LEAVE, name: 'documentcategory_sick_leave' },
  { id: REPORT, name: 'documentcategory_report' },
  { id: MEDICAL_IMAGING, name: 'documentcategory_medical_imaging' },
  { id: PREVENTION, name: 'documentcategory_prevention' },
  { id: OTHER, name: 'documentcategory_other' },
];

type AvaibilityOption = {
  value: number;
  timeperiod: DurationInputArg2;
};

export const onLineAvailabilityLimitOptions: AvaibilityOption[] = [
  { value: 3, timeperiod: 'days' },
  { value: 1, timeperiod: 'weeks' },
  { value: 2, timeperiod: 'weeks' },
  { value: 3, timeperiod: 'weeks' },
  { value: 1, timeperiod: 'months' },
  { value: 2, timeperiod: 'months' },
  { value: 3, timeperiod: 'months' },
  { value: 4, timeperiod: 'months' },
  { value: 5, timeperiod: 'months' },
  { value: 6, timeperiod: 'months' },
  { value: 9, timeperiod: 'months' },
  { value: 1, timeperiod: 'years' },
  { value: 18, timeperiod: 'months' },
  { value: 2, timeperiod: 'years' },
];

export const denyAvailabilityXHoursBeforeOptions: AvaibilityOption[] = [
  { value: 1, timeperiod: 'hours' },
  { value: 2, timeperiod: 'hours' },
  { value: 3, timeperiod: 'hours' },
  { value: 6, timeperiod: 'hours' },
  { value: 12, timeperiod: 'hours' },
  { value: 24, timeperiod: 'hours' },
  { value: 48, timeperiod: 'hours' },
  { value: 72, timeperiod: 'hours' },
  { value: 1, timeperiod: 'weeks' },
  { value: 2, timeperiod: 'weeks' },
  { value: 3, timeperiod: 'weeks' },
  { value: 1, timeperiod: 'months' },
  { value: 3, timeperiod: 'months' },
  { value: 6, timeperiod: 'months' },
];

export const onlineVisibilityBeforeTlc: AvaibilityOption[] = [
  // { value: 30, timeperiod: 'minutes' },
  { value: 1, timeperiod: 'hours' },
  { value: 2, timeperiod: 'hours' },
  { value: 3, timeperiod: 'hours' },
  { value: 6, timeperiod: 'hours' },
  { value: 12, timeperiod: 'hours' },
  { value: 24, timeperiod: 'hours' },
  { value: 48, timeperiod: 'hours' },
  { value: 72, timeperiod: 'hours' },
  { value: 1, timeperiod: 'weeks' },
  { value: 2, timeperiod: 'weeks' },
  { value: 3, timeperiod: 'weeks' },
  { value: 4, timeperiod: 'weeks' },
  { value: 6, timeperiod: 'weeks' },
  { value: 8, timeperiod: 'weeks' },
  { value: 9, timeperiod: 'weeks' },
  { value: 10, timeperiod: 'weeks' },
  { value: 11, timeperiod: 'weeks' },
  { value: 12, timeperiod: 'weeks' },
  { value: 13, timeperiod: 'weeks' },
  { value: 14, timeperiod: 'weeks' },
  { value: 15, timeperiod: 'weeks' },
  { value: 16, timeperiod: 'weeks' },
  { value: 17, timeperiod: 'weeks' },
  { value: 18, timeperiod: 'weeks' },
  { value: 19, timeperiod: 'weeks' },
  { value: 20, timeperiod: 'weeks' },
  { value: 21, timeperiod: 'weeks' },
];

export type DayOfWeek = {
  key:
    | typeof MONDAY
    | typeof TUESDAY
    | typeof WEDNESDAY
    | typeof THURSDAY
    | typeof FRIDAY
    | typeof SATURDAY
    | typeof SUNDAY;
  label: string;
  value: number;
};

export const daysOfTheWeek: Array<DayOfWeek> = [
  { key: MONDAY, label: 'day_of_the_week_monday', value: 0 },
  { key: TUESDAY, label: 'day_of_the_week_tuesday', value: 1 },
  { key: WEDNESDAY, label: 'day_of_the_week_wednesday', value: 2 },
  { key: THURSDAY, label: 'day_of_the_week_thursday', value: 3 },
  { key: FRIDAY, label: 'day_of_the_week_friday', value: 4 },
  { key: SATURDAY, label: 'day_of_the_week_saturday', value: 5 },
  { key: SUNDAY, label: 'day_of_the_week_sunday', value: 6 },
];

export const MAX_PRICE_INPUT_TLC = 200;

// ID SELECTEUR
export const PREFIX_CALENDAR_HTML_ID = 'big-calendar-id-event-';
export const SELECT_IS_TLC = 'select-is-tlc-';
export const SELECT_IS_DOCUMENT_UPLOAD = 'select-is-document-upload-';
export const SELECT_OPTION_TLE_DOCUMENT = 'tele-expertise';
export const CONTAINER_TLC_ID = 'container_tlc_id';
export const OPEN_TOK_COMMON_CLASSNAME = 'opentok-common';
export const PUBLISHER_VIDEO_CONTAINER_TLC_ID = 'pub_video_container_tlc_id';
export const PUBLISHER_SCREEN_SHARING_VIDEO_CONTAINER_TLC_ID_TARGET =
  'pub_screen_sharing_video_container_tlc_id_target';
export const PUBLISHER_SCREEN_SHARING_VIDEO_CONTAINER_TLC_ID_SOURCE =
  'pub_screen_sharing_video_container_tlc_id_source';
export const SUBSCRIBER_VIDEO_CONTAINER_TLC_CLASSNAME = 'OTSubscriberContainer';
export const SUBSCRIBER_TYPE_CAMERA_VIDEO_CONTAINER_TLC_CLASSNAME =
  'OTSubscriber_Camera';
export const SUBSCRIBER_TYPE_SCREEN_VIDEO_CONTAINER_TLC_CLASSNAME =
  'OTSubscriber_ScreenSharing';

// DATE
export const FORMAT_HH_MM = 'HH:mm';
export const FORMAT_HH_MM_SS = 'HH:mm:ss';
export const FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const FORMAT_DDDD_DD_MM_YYYY = 'dddd DD/MM/YYYY';
export const FORMAT_YYYY_MM_DD_FOR_SERVER = 'YYYY-MM-DD';
export const FORMAT_TIMESLOT_START_DATE = 'ddd HH:mm';
export const FORMAT_APPOINTMENT_DATE = 'DD/MM à HH:mm'; // TODO: use a i18n key;
export const FORMAT_APPOINTMENT_HISTORY = 'DD/MM/YYYY (HH[h]mm)';
export const FORMAT_TLC_PLANING = 'dddd DD MMMM';
export const FORMAT_END_CYCLE_DATE = 'dddd DD MMMM YYYY';
export const FORMAT_EVENT_DATACY = 'DD[_]MM[_]HH[_]mm';
export const FORMAT_CHAT_DAY = 'ddd D/MM';
export const FORMAT_TREATMENT_HISTORY_DATE = 'ddd D MMM - HH[h]mm';
export const FORMAT_APPOINTMENT_PRINT_DATE = 'ddd D MMM YYYY';
// 1870-01-01 is the age given to us by Gestion when there's no age
export const DATE_EMPTY_VALUE = '1870-01-01';

type RecurrenceWeekTemplateValueType = WeekTemplateCycle['recurrence'];

export type RecurrenceAppointment = {
  value: AppointmentGetDTO['recurrencePeriod'];
  label: AppointmentGetDTO['recurrencePeriod'];
};
export type RecurrenceWeekTemplate = {
  value: RecurrenceWeekTemplateValueType;
  label: RecurrenceWeekTemplateValueType;
};
// WEEK TYPE
export const RECURRENCES_WEEKTYPE: RecurrenceWeekTemplate[] = [
  {
    value: 'EVERY_1_WEEK',
    label: 'EVERY_1_WEEK',
  },
  {
    value: 'EVERY_2_WEEK',
    label: 'EVERY_2_WEEK',
  },
  {
    value: 'EVERY_3_WEEK',
    label: 'EVERY_3_WEEK',
  },
  {
    value: 'EVERY_4_WEEK',
    label: 'EVERY_4_WEEK',
  },
  {
    value: 'EVERY_5_WEEK',
    label: 'EVERY_5_WEEK',
  },
  {
    value: 'EVERY_6_WEEK',
    label: 'EVERY_6_WEEK',
  },
  {
    value: 'EVERY_7_WEEK',
    label: 'EVERY_7_WEEK',
  },

  {
    value: 'EVERY_8_WEEK',
    label: 'EVERY_8_WEEK',
  },

  {
    value: 'EVERY_9_WEEK',
    label: 'EVERY_9_WEEK',
  },

  {
    value: 'EVERY_10_WEEK',
    label: 'EVERY_10_WEEK',
  },

  {
    value: 'EVERY_11_WEEK',
    label: 'EVERY_11_WEEK',
  },

  {
    value: 'EVERY_12_WEEK',
    label: 'EVERY_12_WEEK',
  },
];

export const RECURRENCES_ABSENCE: RecurrenceAppointment[] = [
  {
    value: 'NO_RECURRENCE',
    label: 'NO_RECURRENCE',
  },
  {
    value: 'EVERY_1_WEEK',
    label: 'EVERY_1_WEEK',
  },
  {
    value: 'EVERY_2_WEEK',
    label: 'EVERY_2_WEEK',
  },
  {
    value: 'EVERY_3_WEEK',
    label: 'EVERY_3_WEEK',
  },
  {
    value: 'EVERY_4_WEEK',
    label: 'EVERY_4_WEEK',
  },
];

export const DAYS = [
  { id: 'DAYS-0', label: 'MONDAY', value: 0 },
  { id: 'DAYS-1', label: 'TUESDAY', value: 1 },
  { id: 'DAYS-2', label: 'WEDNESDAY', value: 2 },
  { id: 'DAYS-3', label: 'THURSDAY', value: 3 },
  { id: 'DAYS-4', label: 'FRIDAY', value: 4 },
  { id: 'DAYS-5', label: 'SATURDAY', value: 5 },
  { id: 'DAYS-6', label: 'SUNDAY', value: 6 },
];

export const PUNCTUAL = [
  {
    id: 'PUNCTUAL-0',
    label: 'common:update_week_type',
    value: false,
  },
  {
    id: 'PUNCTUAL-1',
    label: 'common:update_punctual_week_type',
    value: true,
  },
];

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const DISPLAY_SETTINGS_APPOINTMENT_RANGE = [
  { value: 5, analyticsLabel: GAAgendaViewLabels._5MinutesAppointment },
  { value: 10, analyticsLabel: GAAgendaViewLabels._10MinutesAppointment },
  { value: 15, analyticsLabel: GAAgendaViewLabels._15MinutesAppointment },
  { value: 20, analyticsLabel: GAAgendaViewLabels._20MinutesAppointment },
  { value: 30, analyticsLabel: GAAgendaViewLabels._30MinutesAppointment },
  { value: 40, analyticsLabel: GAAgendaViewLabels._40MinutesAppointment },
  { value: 60, analyticsLabel: GAAgendaViewLabels._60MinutesAppointment },
];
export const APPOINTMENT_MAX_DURATION_IN_MINUTES = 300;
export const APPOINTMENT_DURATION_STEP_IN_MINUTES = 5;

export const ZOOM_100_VALUE = 5.4;

export const DISPLAY_SETTINGS_ZOOM = [
  { value: 4, percent: 75, analyticsLabel: GAAgendaViewLabels._75Zoom },
  {
    value: ZOOM_100_VALUE,
    percent: 100,
    analyticsLabel: GAAgendaViewLabels._100Zoom,
  },
  { value: 7, percent: 130, analyticsLabel: GAAgendaViewLabels._130Zoom },
  {
    value: 15,
    percent: 250,
    analyticsLabel: GAAgendaViewLabels._250Zoom,
  },
  {
    value: 19,
    percent: 350,
    analyticsLabel: GAAgendaViewLabels._350Zoom,
  },
];

// TAB URL

// agenda-settings
export const DEFAULT_AGENDA_APPOINTMENT_LENGTH = 15;
export const DEFAULT_AGENDA_AVAILABILITY_MAX_WEEK_PERIOD = 52;
export const AGENDA_APOINTMENT_ALLOWED_LENGTH = [
  ...DISPLAY_SETTINGS_APPOINTMENT_RANGE.map(item => item.value),
];

export const CONSULTATION_TYPES: Array<{
  value: ConsultationReason['consultationType'];
  label: string;
  needsTlcOffer: boolean;
  needsOnlineAppointmentOffer: boolean;
}> = [
  {
    value: 'PHYSICAL',
    label: 'consultationreasonrow_consultationtype_physical',
    needsTlcOffer: false,
    needsOnlineAppointmentOffer: false,
  },
  {
    value: 'TELECONSULTATION',
    label: 'consultationreasonrow_consultationtype_tlc',
    needsTlcOffer: true,
    needsOnlineAppointmentOffer: false,
  },
  {
    value: 'PHYSICAL_OR_TELECONSULTATION',
    label: 'consultationreasonrow_consultationtype_both',
    needsTlcOffer: true,
    needsOnlineAppointmentOffer: true,
  },
  {
    value: 'HOME_VISIT',
    label: 'consultationreasonrow_consultationtype_homevisit',
    needsTlcOffer: false,
    needsOnlineAppointmentOffer: false,
  },
];

export const CONVENTION_SECTORS = [
  { id: SECTOR1, name: 'convention_sector_1' },
  { id: SECTOR1_DP_OPTAM, name: 'convention_sector_1_dp_optam' },
  { id: SECTOR2, name: 'convention_sector_2' },
  { id: SECTOR2_OPTAM, name: 'convention_sector_2_optam' },
  { id: SECTOR2_OPTAM_CO, name: 'convention_sector_2_optam_co' },
  { id: SECTOR3, name: 'convention_sector_3' },
  { id: AFFILIATED, name: 'convention_sector_affiliated' },
  { id: NOT_AFFILIATED, name: 'convention_sector_not_affiliated' },
];

export const TYPES_RATE_BY_ACT = [
  { id: 'CONVENTIONED', name: 'pricing_by_act_conventional' },
  {
    id: 'NOT_CONVENTIONED',
    name: 'pricing_by_act_not_conventional',
  },
];

export const WATCH_PING_PATIENT = 'WATCH_PING_PATIENT';
export const UNWATCH_PING_PATIENT = 'UNWATCH_PING_PATIENT';
export const ACTION_ANNOUNCE = 'ANNOUNCE';

export const PERMISSIONS_TO_CHECK = ['microphone', 'camera'];

export const DEFAULT_MIN_AGE = 0;
export const DEFAULT_MAX_AGE = 150;

export const PAYMENT_TYPE_OTHER = 'PAYMENT_TYPE_OTHER';
export const PAYMENT_TYPE_FREE = 'PAYMENT_TYPE_FREE';
export const PAYMENT_TYPE_DEFAULT = 'PAYMENT_TYPE_DEFAULT';
export const PAYMENT_TYPE_LATER = 'PAYMENT_TYPE_LATER';

// STATUS COPY/PAST
export const TO_PASTE = 'TO_PASTE';
export const LOADING_PASTE = 'LOADING_PASTE';
export const INVALID_PASTE = 'INVALID_PASTE';
export const SUCCESS_PASTE = 'SUCCESS_PASTE';
export const CANCEL_PASTE = 'CANCEL_PASTE';

export const ENTER_DELAY_TOOLTIP_CALENDAR = 500;
export const LEAVE_DELAY_TOOLTIP_CALENDAR = 200;

export const ALLDAY = 'ALLDAY';
export const AFTERNOON = 'AFTERNOON';
export const MORNING = 'MORNING';

export const PRINT_APPOINTMENT_OPTIONS = [
  { id: ALLDAY, name: 'print_appointment_option_allday' },
  { id: MORNING, name: 'print_appointment_option_morning' },
  { id: AFTERNOON, name: 'print_appointment_option_afternoon' },
];

export const DEFAULT_TIMEZONE = 'Europe/Paris';

// CODE ERROR
export const COMPUTING_AVAILABILITIES = 'COMPUTING_AVAILABILITIES';

// MultiAgenda
export const MAX_DAY_MULTI_CALENDAR_DISPLAYED = 12;

export const CALENDAR_SIDEBAR_WIDTH = 71;
export const CALENDAR_TIMERANGE_HEADER_HEIGHT = 40;
export const CALENDAR_NAME_HEADER_HEIGHT = 40;

export const CHAT_MESSAGE_TIME_FORMAT = 'DD MMMM YYYY à HH[h]mm';
export const CHAT_MESSAGE_TIME_FORMAT_WITH_DAY = 'dddd DD MMMM YYYY à HH[h]mm';

export const NO_PREFERENCES = 'NO_PREFERENCES';

// speciality code in Center object
export const VACCINATION_CENTER_CODE = 'VAC01';

export const INJECTION_TYPES = [
  {
    id: 'FIRST',
    name: 'injection_type__first_',
    datacy: 'first',
  },
  {
    id: 'FIRST_21',
    name: 'injection_type__first_with_delay',
    datacy: 'first_21',
    nextAppointmentDelayInDays: 21,
  },
  {
    id: 'FIRST_42',
    name: 'injection_type__first_with_delay',
    datacy: 'first_42',
    nextAppointmentDelayInDays: 42,
  },
  {
    id: 'FIRST_84',
    name: 'injection_type__first_with_delay',
    datacy: 'first_84',
    nextAppointmentDelayInDays: 84,
  },

  { id: 'SECOND', name: 'injection_type__second', datacy: 'second' },
  { id: 'BOOSTER', name: 'injection_type__booster', datacy: 'booster' },
  { id: 'NONE', name: 'injection_type__none', datacy: 'none' },
];

export const IS_ACTIVATED_SHARE_DOCUMENT_WITH_LGC_FEATURE = false;

export const KEY_LANG = 'maiia_lang';

export enum COMPLEMENTARY_PRESCRIPTION_TYPES {
  MEDICAL_REPORT = 'MEDICAL_REPORT',
  FREE_PRESCRIPTION = 'FREE_PRESCRIPTION',
  PARAMEDICALE = 'PARAMEDICALE',
  IMAGERIE = 'IMAGERIE',
  BIOLOGIE = 'BIOLOGIE',
}

export const LOCAL_PERSISTANCE_TIME = 7 * 24 * 60 * 60 * 1000;

export const ENTER_KEYBOARD_CODE = 13;

export const APP_DOWNLOAD_LINK = 'https://maiiapro.page.link/home';
export const CONNECT_DOWNLOAD_LINK = 'https://maiiaconnect.page.link/3N7P';
export const TLC_UPLOADING_DOCUMENT = 'TLC_UPLOADING_DOCUMENT';
export const UNABLE_TO_SUBSCRIBE_TO_STREAM = 'UNABLE_TO_SUBSCRIBE_TO_STREAM';
export const MANUAL_REFRESH_REASON = 'manualRefreshReason';

export const NameRegEx = /^\s*([A-Za-z]*([.,] |[-']|\s)?)+[A-Za-z]+\.?\s*$/i;
export const NssRegEx = /^(?!.*(\+\d{1,2}|0))[\d\s]*$/;
export const PhoneRegEx = /^(?:(\+\d{1,2}|0))[\d\s]*$/;
export const BirthDateRegEx = /^[0-9]{2}[/]{1}[0-9]{0,2}[/]{0,1}[0-9]{0,4}$/;
export const EmailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PAT_MEDICINES_URL = 'https://pat.maiia.com/medicaments/';
export const MAIIA_MEDICINES_URL = 'https://maiia.com/medicaments/';
export const UTM_MEDIUM_WEB_PRO = 'WEBPRO';
export const UTM_MEDIUM_PRESCRIPTION = 'PRESCRIPTION';

export const GA_EVENT_CATEGORIES: GAEventCategoriesType = [
  {
    name: GACategories.Header,
    actions: [
      {
        name: GAActions.SelectSearch,
        labels: [
          GALabels.PatientAppointment,
          GALabels.CenterAppointment,
          GALabels.PractitionerAppointment,
        ],
      },
      {
        name: GAActions.ClickSearchActionIcon,
        labels: [
          GALabels.GoPatientFile,
          GALabels.GoCenterAgenda,
          GALabels.GoPractitionerAgenda,
        ],
      },
      {
        name: GAActions.ClickNotificationCenter,
      },
      {
        name: GAActions.ClickNotification,
        labels: [
          GALabels.DocumentNotification,
          GALabels.NewsNotification,
          GALabels.AppointmentNotification,
          GALabels.BillingNotification,
        ],
      },
      {
        name: GAActions.ClickActionHistory,
      },
      {
        name: GAActions.ClickDocumentHistory,
      },
      {
        name: GAActions.ClickChatIcon,
      },
      {
        name: GAActions.ClickPatientList,
      },
      {
        name: GAActions.ClickTransactions,
      },
      {
        name: GAActions.ClickAgendaSetting,
      },
      {
        name: GAActions.ClickAdministration,
      },
      {
        name: GAActions.ClickStatistic,
      },
      {
        name: GAActions.ClickHelp,
      },
      {
        name: GAActions.ClickMyAccount,
      },
      {
        name: GAActions.ClickDisconnect,
      },
      {
        name: GAActions.OpenComplexFormResearchedPatient,
      },
      {
        name: GAActions.OpenComplexFormResearchedPractitioner,
      },
    ],
  },
  {
    name: GACategories.PatientList,
    actions: [
      {
        name: GAActions.ClickSearch,
        labels: [
          GALabels.Name,
          GALabels.PhoneNumber,
          GALabels.NSS,
          GALabels.BirthDate,
          GALabels.UNKNOWN,
        ],
      },
      {
        name: GAActions.ClickFusion,
        labels: [GALabels.OK, GALabels.ERROR],
      },
    ],
  },
  {
    name: GACategories.SimpleFormBooking,
    actions: [
      {
        name: GAActions.OpenSimpleForm,
      },
      {
        name: GAActions.SelectConsultationReason,
      },
      {
        name: GAActions.SelectConsultationType,
        labels: [
          GALabels.PHYSICAL,
          GALabels.TELECONSULTATION,
          GALabels.HOMEVISIT,
        ],
      },
      {
        name: GAActions.ClickAddFirstConsultationReason,
      },
      {
        name: GAActions.SelectSuggestedPatient,
      },
      {
        name: GAActions.SelectResearchedPatient,
      },
      {
        name: GAActions.SelectConsultationReason,
      },
      {
        name: GAActions.CreatePatient,
      },
      {
        name: GAActions.ClickComment,
      },
      {
        name: GAActions.ClickValidate,
      },
      {
        name: GAActions.ClickComplexForm,
      },
      {
        name: GAActions.ClickOther,
      },
      {
        name: GAActions.ClickOtherAppointment,
      },
      {
        name: GAActions.SelectOtherAppointmentRecurrence,
        labels: [
          GALabels.NO_RECURRENCE,
          GALabels.EVERY_1_DAY,
          GALabels.EVERY_1_WEEK,
          GALabels.EVERY_2_WEEK,
          GALabels.EVERY_3_WEEK,
          GALabels.EVERY_4_WEEK,
        ],
      },
      {
        name: GAActions.SelectOtherAppointmentColor,
      },
      {
        name: GAActions.SelectAbsenceRecurrence,
        labels: [
          GALabels.NO_RECURRENCE,
          GALabels.EVERY_1_DAY,
          GALabels.EVERY_1_WEEK,
          GALabels.EVERY_2_WEEK,
          GALabels.EVERY_3_WEEK,
          GALabels.EVERY_4_WEEK,
        ],
      },
      {
        name: GAActions.SaveOtherAppointment,
      },
      {
        name: GAActions.OpenComplexFormMoreOptions,
      },
      {
        name: GAActions.OpenComplexFormReschedule,
      },
      {
        name: GAActions.SaveAbsence,
      },
      {
        name: GAActions.ClickAbsence,
      },
    ],
  },
  {
    name: GACategories.ComplexFormBooking,
    actions: [
      {
        name: GAActions.OpenComplexForm,
      },
      {
        name: GAActions.CloseComplexForm,
      },
      {
        name: GAActions.SelectCenter,
      },
      {
        name: GAActions.SelectSpecialty,
      },
      {
        name: GAActions.SelectConsultationReason,
        labels: [
          GALabels.SearchConsultationReason,
          GALabels.OtherConsultationReason,
        ],
      },
      {
        name: GAActions.SelectConsultationType,
        labels: [
          GALabels.PHYSICAL,
          GALabels.TELECONSULTATION,
          GALabels.HOMEVISIT,
        ],
      },
      {
        name: GAActions.ClickAddFirstConsultationReason,
      },
      {
        name: GAActions.SelectNoPreferencePractitioner,
      },
      {
        name: GAActions.SelectResearchRelatedPractitioner,
      },
      {
        name: GAActions.SelectOtherPractitioner,
      },
      {
        name: GAActions.SelectSuggestedPatient,
      },
      {
        name: GAActions.SelectResearchedPatient,
      },
      {
        name: GAActions.CreatePatient,
      },
      {
        name: GAActions.SelectDate,
      },
      {
        name: GAActions.SelectHour,
      },
      {
        name: GAActions.SelectDuration,
      },
      {
        name: GAActions.SelectFilter,
      },
      {
        name: GAActions.SelectDuration,
      },
      {
        name: GAActions.ClickMultipleAppointment,
      },
      {
        name: GAActions.AddAppointmentCommentary,
      },
      {
        name: GAActions.ClickCancel,
      },
      {
        name: GAActions.ClickValidate,
      },
      {
        name: GAActions.ClickCallTLS,
      },
      {
        name: GAActions.EditPatient,
      },
      {
        name: GAActions.ClickBlockPatient,
      },
      {
        name: GAActions.ClickUnBlockPatient,
      },
      {
        name: GAActions.ClickDeletePatient,
      },
      {
        name: GAActions.ClickPatientTab,
      },
      {
        name: GAActions.ClickAppointmentTab,
      },
      {
        name: GAActions.ClickDocumentTab,
      },
      {
        name: GAActions.CloseDocumentWindows,
      },
      {
        name: GAActions.ClickDocumentSharedByPractitioner,
      },
      {
        name: GAActions.ClickDocumentSharedByPatient,
      },
      {
        name: GAActions.SelectDocumentCategory,
      },
      {
        name: GAActions.ShareAutomatically,
        labels: [GALabels.Yes, GALabels.No],
      },
      {
        name: GAActions.ClickSendDocument,
      },
      {
        name: GAActions.ClickCancelDocument,
      },
      {
        name: GAActions.SelectAvailability,
      },
      {
        name: GAActions.UnSelectAvailability,
      },
      {
        name: GAActions.OpenRecurrencyAppointmentsMode,
      },
      {
        name: GAActions.ConfirmRecurrencyAppointmentsMode,
      },
      {
        name: GAActions.CancelRecurrencyAppointmentsMode,
      },
      {
        name: GAActions.UnselectedOverbookedTimeslot,
      },
      {
        name: GAActions.ClickPatientReferral,
      },
    ],
  },
  {
    name: GACategories.SideBarAgenda,
    actions: [
      {
        name: GASideBarAgendaActions.MiniCalendarDatePicker,
      },
      {
        name: GASideBarAgendaActions.ClickPreviousMonth,
      },
      {
        name: GASideBarAgendaActions.ClickNextMonth,
      },
      {
        name: GASideBarAgendaActions.ClickMultipleCenterView,
        labels: [
          GASideBarAgendaLabels._2,
          GASideBarAgendaLabels._3,
          GASideBarAgendaLabels._4,
          GASideBarAgendaLabels._5,
          GASideBarAgendaLabels._6,
        ],
      },
      {
        name: GASideBarAgendaActions.ClickMultiplePractitionerView,
        labels: [
          GASideBarAgendaLabels._2,
          GASideBarAgendaLabels._3,
          GASideBarAgendaLabels._4,
          GASideBarAgendaLabels._5,
          GASideBarAgendaLabels._6,
        ],
      },
      {
        name: GASideBarAgendaActions.ClickCenter,
      },
      {
        name: GASideBarAgendaActions.SelectCenter,
      },
      {
        name: GASideBarAgendaActions.ClickSearchUser,
      },
      {
        name: GASideBarAgendaActions.ClickOtherUser,
      },
      {
        name: GASideBarAgendaActions.ClickOwnUser,
      },
      {
        name: GASideBarAgendaActions.ClickSelectAllPractitioners,
      },
      {
        name: GASideBarAgendaActions.ClickUnselectAllPractitioners,
      },
    ],
  },
  {
    name: GACategories.Chat,
    actions: [
      {
        name: GAActions.ClickCenter,
      },
      {
        name: GAActions.SelectCenter,
      },
      {
        name: GAActions.ThreadHistory,
      },
      {
        name: GAActions.ClickGroupCreation,
      },
      {
        name: GAActions.ClickValidateGroupCreation,
      },
      {
        name: GAActions.ClickOwnChatRoom,
      },
      {
        name: GAActions.ClickCreateTask,
      },
      {
        name: GAActions.ClickDeleteTask,
      },
      {
        name: GAActions.ClickValidateTask,
      },
      {
        name: GAActions.ClickThread,
      },
      {
        name: GAActions.ClickSharePatientFile,
      },
      {
        name: GAActions.SelectPatientFile,
      },
      {
        name: GAActions.ClickShareDocument,
      },
      {
        name: GAActions.CloseDocumentWindows,
      },
      {
        name: GAActions.ClickCancelDocument,
      },
      {
        name: GAActions.SelectDocumentCategory,
      },
      {
        name: GAActions.ShareAutomatically,
        labels: [GALabels.Yes, GALabels.No],
      },
      {
        name: GAActions.ClickSendDocument,
      },
    ],
  },
  {
    name: GACategories.TeleconsultationSession,
    actions: [
      {
        name: GATlcSessionActions.ClickRefuseDirectTLC,
      },
      {
        name: GATlcSessionActions.ClickWaitPatientDirectTLC,
      },
      {
        name: GATlcSessionActions.ClickLaunchDirectTLC,
      },
      {
        name: GATlcSessionActions.ClickBillPatient,
        labels: [
          GATlcLabels.PAYMENT_TYPE_FREE,
          GATlcLabels.PAYMENT_TYPE_DEFAULT,
          GATlcLabels.PAYMENT_TYPE_OTHER,
          GATlcLabels.PAYMENT_TYPE_LATER,
        ],
      },
      {
        name: GATlcSessionActions.ClickCameraOnOff,
        labels: [GATlcLabels.ON, GATlcLabels.OFF],
      },
      {
        name: GATlcSessionActions.ClickMicroOnOff,
        labels: [GATlcLabels.ON, GATlcLabels.OFF],
      },
      {
        name: GATlcSessionActions.ClickShareScreen,
      },
      {
        name: GATlcSessionActions.ClickHangup,
      },
      {
        name: GATlcSessionActions.ClickMoreOption,
        labels: [GATlcLabels.VIDEO_CHOICE, GATlcLabels.AUDIO_CHOICE],
      },
      {
        name: GATlcSessionActions.ClickCancelHangup,
      },
      {
        name: GATlcSessionActions.ClickConfirmHangup,
      },
      {
        name: GATlcSessionActions.ClickPatientTab,
      },
      {
        name: GATlcSessionActions.ClickAppointmentTab,
      },
      {
        name: GATlcSessionActions.ClickDocumentTab,
      },
      {
        name: GATlcSessionActions.CloseDocumentWindows,
      },
      {
        name: GATlcSessionActions.ClickDocumentSharedByPractitioner,
      },
      {
        name: GATlcSessionActions.ClickDocumentSharedByPatient,
      },
      {
        name: GATlcSessionActions.SelectDocumentCategory,
      },
      {
        name: GATlcSessionActions.ShareAutomatically,
        labels: [GATlcLabels.Yes, GATlcLabels.No],
      },
      {
        name: GATlcSessionActions.ClickSendDocument,
      },
      {
        name: GATlcSessionActions.ClickCancelDocument,
      },
      {
        name: GATlcSessionActions.ClickAccessPatientFile,
      },
      {
        name: GATlcSessionActions.ClickFullThirdPartyPayment,
      },
    ],
  },
  {
    name: GACategories.Transactions,
    actions: [
      { name: GAActions.ClickExport },
      {
        name: GATransactionActions.ClickBill,
        labels: [
          GATransactionLabels.lessThan1Day,
          GATransactionLabels.lessThan2Days,
          GATransactionLabels.lessThan3Days,
          GATransactionLabels.lessThan4Days,
          GATransactionLabels.lessThan5Days,
          GATransactionLabels.lessThan6Days,
          GATransactionLabels.lessThan7Days,
        ],
      },
      { name: GATransactionActions.ClickAllPractitioners },
    ],
  },
  {
    name: GACategories.TeleconsultationSetting,
    actions: [
      { name: GATlcSettingActions.OpenTeleconsultationSetting },
      { name: GATlcSettingActions.ClickTeleconsultationOnDemand },
      {
        name: GATlcSettingActions.SelectKnownPatient,
        labels: [
          GATlcSettingLabels.SelectKnownPatient,
          GATlcSettingLabels.SelectAllPatient,
        ],
      },
      { name: GATlcSettingActions.ClickLaunchTeleconsultationOnDemand },
      { name: GATlcSettingActions.ClickAccessWaitingRoom },
      { name: GATlcSettingActions.ClickTeleconsultationSetting },
      {
        name: GATlcSettingActions.Network,
        labels: [
          GATlcSettingLabels.ExcellentConnexion,
          GATlcSettingLabels.MediumConnexion,
          GATlcSettingLabels.BadConnexion,
        ],
      },
      { name: GATlcSettingActions.ClickTestAudio },
      { name: GATlcSettingActions.ClickTestCamera },
      { name: GATlcSettingActions.SelectDefaultPriceTeleconsultation },
      { name: GATlcSettingActions.ClickCancelTeleconsultationSetting },
      { name: GATlcSettingActions.ClickValidateTeleconsultationSetting },
    ],
  },
  {
    name: GACategories.AgendaView,
    actions: [
      { name: GAAgendaViewActions.ClickPreviousWeek },
      { name: GAAgendaViewActions.ClickNextWeek },
      { name: GAAgendaViewActions.ClickAgendaSettingView },
      {
        name: GAAgendaViewActions.SelectAgendaView,
        labels: [
          GAAgendaViewLabels.DAY,
          GAAgendaViewLabels.PLANNING,
          GAAgendaViewLabels.WEEK,
        ],
      },
      {
        name: GAAgendaViewActions.SelectZoom,
        labels: [
          GAAgendaViewLabels._75Zoom,
          GAAgendaViewLabels._100Zoom,
          GAAgendaViewLabels._130Zoom,
          GAAgendaViewLabels._250Zoom,
          GAAgendaViewLabels._350Zoom,
        ],
      },
      {
        name: GAAgendaViewActions.SelectDefaultTimeAppointment,
        labels: [
          GAAgendaViewLabels._5MinutesAppointment,
          GAAgendaViewLabels._10MinutesAppointment,
          GAAgendaViewLabels._15MinutesAppointment,
          GAAgendaViewLabels._20MinutesAppointment,
          GAAgendaViewLabels._30MinutesAppointment,
          GAAgendaViewLabels._40MinutesAppointment,
          GAAgendaViewLabels._60MinutesAppointment,
        ],
      },
      { name: GAAgendaViewActions.OpenComplexFormPlus },
      {
        name: GACopyPasteAgendaActions.ClickMultipleDuplicationIcon,
      },
      { name: GACopyPasteAgendaActions.ClickCopyPasteAgendaOneOrMore },
      {
        name: GACopyPasteAgendaActions.ClickCopyPasteAgendaAllDay,
      },
      {
        name: GACopyPasteAgendaActions.ClickCancelCopyModeAgenda,
      },
      {
        name: GACopyPasteAgendaActions.ClickCancelPasteModeAgenda,
      },
      {
        name: GACopyPasteAgendaActions.ClickCopyAppointment,
      },
      {
        name: GACopyPasteAgendaActions.ClickPasteAppointment,
      },
    ],
  },
  {
    name: GACategories.PatientFile,
    actions: [
      {
        name: GAActions.ClickCallTLS,
      },
      {
        name: GAActions.EditPatient,
      },
      {
        name: GAActions.ClickBlockPatient,
      },
      {
        name: GAActions.ClickUnBlockPatient,
      },
      {
        name: GAActions.ClickDeletePatient,
      },
      {
        name: GAActions.ClickPatientTab,
      },
      { name: GAActions.ClickEhrTab },
      {
        name: GAActions.ClickAppointmentTab,
      },
      {
        name: GAActions.ClickDocumentTab,
      },
      {
        name: GAActions.CloseDocumentWindows,
      },
      {
        name: GAActions.ClickDocumentSharedByPractitioner,
      },
      {
        name: GAActions.ClickDocumentSharedByPatient,
      },
      {
        name: GAActions.SelectDocumentCategory,
      },
      {
        name: GAActions.ShareAutomatically,
        labels: [GALabels.Yes, GALabels.No],
      },
      {
        name: GAActions.ClickSendDocument,
      },
      {
        name: GAActions.ClickCancelDocument,
      },
      {
        name: GAActions.ClickPatientReferral,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingConsultationReason,
    actions: [
      {
        name:
          GAAgendaSettingConsultationReasonActions.ClickCreateSimpleConsultationReason,
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.ClickCreateMultipleConsultationReason,
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.SwitchSimpleConsultationReason,
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.SwitchConsultationReasonGroup,
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.SelectDurationConsultationReason,
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.ClickKnowPatientLimitConsultationReason,
        labels: [
          GAAgendaSettingConsultationReasonLabels.KnownPatient,
          GAAgendaSettingConsultationReasonLabels.AllPatient,
        ],
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.ClickNotificationConsultationReason,
        labels: [
          GAAgendaSettingConsultationReasonLabels.DisabledNotification,
          GAAgendaSettingConsultationReasonLabels.Notification,
        ],
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.SelectConsultationReasonType,
        labels: [
          GAAgendaSettingConsultationReasonLabels.Physical,
          GAAgendaSettingConsultationReasonLabels.Video,
          GAAgendaSettingConsultationReasonLabels.PhysicalAndVideo,
        ],
      },
      {
        name:
          GAAgendaSettingConsultationReasonActions.SelectVisibleOnlineConsultationReason,
        labels: [
          GAAgendaSettingConsultationReasonLabels.Visible,
          GAAgendaSettingConsultationReasonLabels.Invisible,
          GAAgendaSettingConsultationReasonLabels.OnlyPro,
        ],
      },
      {
        name: GAAgendaSettingConsultationReasonActions.SelectResource,
      },
      {
        name: GAAgendaSettingConsultationReasonActions.OnlineOpeningHour,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingPractitionerProfile,
    actions: [
      {
        name: GAAgendaSettingPractitionerProfileActions.SelectExpertise,
      },
      {
        name: GAAgendaSettingPractitionerProfileActions.SelectConvention,
      },
      {
        name: GAAgendaSettingPractitionerProfileActions.ClickAddAct,
      },
      {
        name: GAAgendaSettingPractitionerProfileActions.SelectRefund,
      },
      {
        name: GAAgendaSettingPractitionerProfileActions.SelectPaymentChoice,
      },
      {
        name:
          GAAgendaSettingPractitionerProfileActions.SelectOpenAppointmentDuration,
      },
      {
        name:
          GAAgendaSettingPractitionerProfileActions.SelectInterdictionAppointmentDuration,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingResources,
    actions: [
      {
        name: AnalyticsResourcesActions.ClickAddResource,
      },
      {
        name: AnalyticsResourcesActions.CloseAddResource,
      },
      {
        name: AnalyticsResourcesActions.SelectResource,
      },
      {
        name: AnalyticsResourcesActions.ClickConfirmationAddResource,
      },
      {
        name: AnalyticsResourcesActions.SelectCapacityNumberResource,
      },
      {
        name: AnalyticsResourcesActions.ClickBlockingResource,
        labels: [
          AnalyticsResourcesLabels.Blocking,
          AnalyticsResourcesLabels.UnBlocking,
        ],
      },
      {
        name: AnalyticsResourcesActions.SelectUnavailabilityDuration,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingInstructions,
    actions: [
      {
        name: AnalyticsInstructionsActions.SetDefaultCenterInstruction,
      },
      {
        name: AnalyticsInstructionsActions.SetDefaultPractitionerInstruction,
      },
      {
        name:
          AnalyticsInstructionsActions.SetCenterConsultationReasonInstruction,
      },
      {
        name:
          AnalyticsInstructionsActions.SetPractitionerConsultationReasonInstruction,
      },
      {
        name: AnalyticsInstructionsActions.InsertLink,
      },
      {
        name: AnalyticsInstructionsActions.AttachFiles,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingTerminalInstructions,
    actions: [
      {
        name: GATerminalInstructionSettingActions.SetDefaultCenterInstruction,
      },
      {
        name:
          GATerminalInstructionSettingActions.SetDefaultPractitionerInstruction,
      },
      {
        name:
          GATerminalInstructionSettingActions.SetCenterConsultationReasonInstruction,
      },
      {
        name:
          GATerminalInstructionSettingActions.SetPractitionerConsultationReasonInstruction,
      },
    ],
  },
  {
    name: GACategories.AgendaSettingNotifications,
    actions: [
      { name: GANotificationSettingActions.SelectCenterSMS },
      { name: GANotificationSettingActions.SelectPersonalizedSMS },
    ],
  },
  {
    name: GACategories.AgendaSettingSecretaryInstruction,
    actions: [
      {
        name:
          AnalyticsSecretaryInstructionsActions.SetDefaultPractitionerSecretaryInstruction,
      },
    ],
  },
  {
    name: GACategories.AppointmentDetailsDialog,
    actions: [
      { name: GACopyPasteAgendaActions.ClickOneAppointmentDuplication },
      { name: GAPrintActions.ClickPrintFromAppointmentInfo },
      { name: GAPrintActions.ClickPrintPortraitFromAppointmentInfo },
      { name: GAPrintActions.ClickPrintLandscapeFromAppointmentInfo },
      { name: GAActions.OpenComplexFormModification },
    ],
  },
  {
    name: GACategories.AppointmentsHistory,
    actions: [
      { name: GAAppointmentsHistoryActions.ScrollToUpcomingAppointments },
      { name: GAAppointmentsHistoryActions.ScrollToNextAppointment },
      { name: GAAppointmentsHistoryActions.SeeMorePastAppointments },
      { name: GAAppointmentsHistoryActions.ToggleAppointmentTreatmentView },
      { name: GAAppointmentsHistoryActions.AddAppointmentsToTreatment },
      { name: GAAppointmentsHistoryActions.RemoveAppointmentsFromTreatment },
      { name: GAAppointmentsHistoryActions.OpenComplexFormPlus },
    ],
  },
  {
    name: GACategories.Authentication,
    actions: [
      { name: GAAuthenticationActions.ClickRememberDevice },
      { name: GAAuthenticationActions.StartMultiFactorAuthentication },
      { name: GAAuthenticationActions.SuccessMultiFactorAuthentication },
      { name: GAAuthenticationActions.FailMultiFactorAuthentication },
      { name: GAAuthenticationActions.SuccessWithoutMultiFactorAuthentication },
    ],
  },
  {
    name: GACategories.Ehr,
    actions: [
      { name: AnalyticsEhrActions.ImportEhrPatientData },
      { name: AnalyticsEhrActions.SavedEhrData },
      { name: AnalyticsEhrActions.DisplayEhrGraph },
    ],
  },
  {
    name: GACategories.WaitingList,
    actions: [
      { name: GAWaitingListActions.ClickWaitingListSwitch },
      { name: GAWaitingListActions.ClickWaitingListTab },
      { name: GAWaitingListActions.ClickSearch },
      { name: GAWaitingListActions.SelectAll },
      { name: GAWaitingListActions.SelectByPractitioner },
      { name: GAWaitingListActions.SelectByPatient },
      { name: GAWaitingListActions.ClickModifyAppointment },
      { name: GAWaitingListActions.ClickDeleteFromWaitingList },
      { name: GAWaitingListActions.ClickDeleteFromWaitingListConfirm },
      { name: GAWaitingListActions.ClickDeleteFromWaitingListCancel },
      {
        name:
          GAWaitingListActions.ClickDeleteFromWaitingListWithAppointmentCancellation,
      },
    ],
  },
  {
    name: GACategories.Upsell,
    actions: [
      { name: GAUpsellActions.ClickUpsellAgendaPromotionalPage },
      { name: GAUpsellActions.ClickUpsellOpenContactRequest },
      { name: GAUpsellActions.ClickUpsellValidationContactRequest },
      { name: GAUpsellActions.ClickUpsellAgendaDocumentInstructions },
      { name: GAUpsellActions.ClickUpsellAgendaDelay },
      { name: GAUpsellActions.ClickUpsellAgendaConsultationReasonOnline },
      {
        name: GAUpsellActions.ClickUpsellAgendaConsultationReasonOnlineOnlyPro,
      },
      { name: GAUpsellActions.ClickUpsellAgendaNotificationsSettings },
      { name: GAUpsellActions.ClickUpsellAgendaProfilePratOnline },
      { name: GAUpsellActions.ClickUpsellAgendaSettingsInstructions },
      { name: GAUpsellActions.ClickUpsellAgendaGeneratePrescriptionBcb },
      {
        name:
          GAUpsellActions.ClickUpsellAgendaGeneratePrescriptionComplementary,
      },
      { name: GAUpsellActions.ClickUpsellAgendaGenerateReport },
      { name: GAUpsellActions.ClickUpsellAgendaWaitingList },
      { name: GAUpsellActions.ClickUpsellAgendaGenerateMedicalCertificate },
      { name: GAUpsellActions.ClickUpsellAgendaDuplicateAppointments },
      { name: GAUpsellActions.ClickUpsellAgendaOmnidocTeleexpertise },
      { name: GAUpsellActions.ClickUpsellAgendaTlc },
    ],
  },
  {
    name: GACategories.Print,
    actions: [
      { name: GAPrintActions.ClickPrintFromAppointmentsHistoryList },
      { name: GAPrintActions.ClickPrintPortraitFromAppointmentsHistoryList },
      { name: GAPrintActions.ClickPrintLandscapeFromAppointmentsHistoryList },
      { name: GAPrintActions.ClickPrintFromAgenda },
      { name: GAPrintActions.ClickPrintPortraitFromAgenda },
      { name: GAPrintActions.ClickPrintLandscapeFromAgenda },
      { name: GAPrintActions.SelectStandardImpressionTypeFromAgenda },
      { name: GAPrintActions.SelectAtHomeImpressionTypeFromAgenda },
    ],
  },
  {
    name: GACategories.Omnidoc,
    actions: [
      { name: GAOmnidocActions.TLEsentOmnidoc },
      { name: GAOmnidocActions.TLEOmnidoc },
    ],
  },
  {
    name: GACategories.Note,
    actions: [
      { name: GANoteActions.ClickPlusButton },
      { name: GANoteActions.SaveNote },
    ],
  },
];

// Image type for documnet

export const BARCODE_AM_NUMBER_LABEL = 'am';
export const BARCODE_FINESS_NUMBER_LABEL = 'finess';
export const TLEX_ROW_COUNT_PER_PAGE = 10;

export const PDF_ROTATION_ANGLE_90 = 90;
export const PDF_ROTATION_ANGLE_270 = 270;

export const STRIPE_STATUS_VERIFIED = 'verified';
export const STRIPE_STATUS_ACTIVE = 'active';

export const REFERRAL = 'REFERRAL';
export const TLCA = 'TLCA';
