export default {
  black: '#000',
  white: '#fff',

  primaryMaiia: {
    xlight: 'hsl(352, 85.2%, 94.7%)',
    light: 'hsl(350, 86%, 80%)',
    main: 'hsl(350 86% 73%)',
  },

  grey: {
    50: 'hsl(0,0%,77.6%)',
    100: '#F7FAFC',
    200: '#EDF2F7',
    300: '#E2E8F0',
    400: '#CBD5E0',
    500: '#A0AEC0',
    600: '#AEC7D0',
    700: '#4A5568',
    800: '#2D3748',
    900: '#1A202C',
  },
  trueGrey: {
    50: '#EFF4F6',
    100: '#F5F8F9',
    200: '#CFDDE3',
    300: '#294B57',
    400: '#AEC7D0',
    500: '#578FA2',
    600: '#578FA2',
    700: '#578FA2',
    800: '#20363D',
    900: '#20363D',
  },
  aqua: {
    100: '#E4F6F5',
    200: '#08A6A0',
    300: '#08A6A0',
    400: '#08A6A0',
    500: '#067F7A',
    600: '#067F7A',
    700: '#067F7A',
  },
  red: {
    50: '#FFF0F0',
    100: '#FFF0F0',
    150: '#FFF0F0',
    200: '#FFD6E4',
    300: '#FB3B7C',
    400: '#FB3B7C',
    500: '#FB3B7C',
    600: '#D41153',
    700: '#D41153',
    800: '#D41153',
    900: '#D41153',
  },
  orange: {
    100: '#FEF7F1',
    150: '#FEF7F1',
    200: '#FFE5C7',
    300: '#FFE5C7',
    400: '#E07800',
    500: '#E07800',
    600: '#E07800',
    700: '#E07800',
    800: '#B76200',
    900: '#B76200',
  },
  yellow: {
    50: '#FEF7F1',
    100: '#FEF7F1',
    200: '#FEF7F1',
    300: '#FFE5C7',
    400: '#FFE5C7',
    500: '#FFE5C7',
    600: '#B76200',
    700: '#B76200',
    800: '#B76200',
    900: '#B76200',
  },
  green: {
    100: '#ECFDF2',
    150: '#ECFDF2',
    200: '#BBF6CF',
    300: '#BBF6CF',
    400: '#00AA3A',
    500: '#00AA3A',
    600: '#00AA3A',
    700: '#00812C',
    800: '#00812C',
    900: '#00812C',
  },
  teal: {
    50: '#E4F6F5',
    100: '#E4F6F5',
    200: '#BEE9E8',
    300: '#08A6A0',
    400: '#08A6A0',
    500: '#08A6A0',
    600: '#08A6A0',
    700: '#067F7A',
    800: '#067F7A',
    900: '#10748E',
  },
  blue: {
    100: '#DBF3FA',
    150: '#9EDAEB',
    200: '#9EDAEB',
    300: '#009DC6',
    400: '#DBF3FA',
    500: '#DBF3FA',
    600: '#009DC6',
    700: '#10748E',
    800: '#10748E',
    900: '#10748E',
    light: '#50B3C8',
    main: '#459AAC',
    hover: '#459AAC',
    dark: '#067F7A',
  },
  indigo: {
    100: '#CFDDE3',
    200: '#9EDAEB',
    300: '#9EDAEB',
    400: '#009DC6',
    500: '#009DC6',
    600: '#009DC6',
    700: '#10748E',
    800: '#10748E',
    900: '#10748E',
  },
  purple: {
    50: '#DBF3FA',
    100: '#DBF3FA',
    200: '#9EDAEB',
    300: '#9EDAEB',
    400: '#009DC6',
    500: '#009DC6',
    600: '#009DC6',
    700: '#009DC6',
    800: '#10748E',
    900: '#10748E',
  },
  pink: {
    50: '#FDE2E2',
    100: '#FDE2E2',
    200: '#FAC7D8',
    300: '#FB3B7C',
    400: '#FB3B7C',
    500: '#FB3B7C',
    600: '#D41153',
    700: '#D41153',
    800: '#D41153',
    900: '#D41153',
  },
  // Create Colors Tag

  tagGreen: {
    200: '#CDEDE2',
    600: '#64C9A7',
  },
  // TagRed
  tagRed: {
    200: '#F9CECE',
    600: '#F56565',
  },
  // TagYellow
  tagYellow: {
    200: '#F9EFC8',
    600: '#ECC94B',
  },
  // TagGrey
  tagGrey: {
    200: '#E5E5E5',
    600: '#A8A8A8',
  },
  tagOrange: {
    200: '#FEF3EA',
    600: '#F8852D',
  },
};
