/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Tanstack Query Version : `4`
 *
 * Implementation for `proConfigGlobalFeatureFlags` hook
 * Endpoint: `/pro/config/global-feature-flags`
 */

import { useQueryClient, useQuery } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

export function makeQueryKey() {
	return ["@@api-pro", "pro", "config", "global-feature-flags"];
}

export function useInvalidateQuery() {
	const queryClient = useQueryClient();
	return function invalidateQuery() {
		return queryClient.invalidateQueries({
			queryKey: makeQueryKey().filter(Boolean),
		});
	};
}

export function useGet(options) {
	return useQuery({
		...options,
		queryKey: makeQueryKey(),
	});
}
