import { useGet as useGetConfigGlobalFeatureFlags } from '@maiia/model/generated/model/api-pro/hooks/proConfigGlobalFeatureFlags';

// Set staleTime for global feature flags.
// This query is invalidated by SYSTEM messages via Websocket
function useGlobalFeatureFlags() {
  return useGetConfigGlobalFeatureFlags({
    staleTime: 5 * 60 * 1000,
  });
}

export { useGlobalFeatureFlags };
