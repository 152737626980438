import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

import { documentLabelCategories } from '@docavenue/pro-shared';
import { PatientGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import { notificationCentersActions } from '../../../src/actions';
import { DocumentNotificationCenter } from '../../../types/pro.types';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import {
  InternetPatientIcon,
  PatientLabelField,
  PatientName,
} from '@/components/molecules/PatientLabel/PatientLabel';
import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

const useStyles = makeStyles()((theme: any) => ({
  notificationDocumentRoot: {
    transition: 'all 0.2s ease',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .documentType': {
      ...theme.typography.blueLink,
      textDecoration: 'underline',
    },
  },
}));

type NotificationDocumentViewProps = {
  notification: DocumentNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const NotificationDocumentView = (props: NotificationDocumentViewProps) => {
  const { notification, closeModal, triggerGAEvent } = props;
  const {
    patientId,
    profileIdPatient,
    firstNamePatient,
    lastNamePatient,
    category,
  } = notification.displayData;
  const router = useRouter();
  const { query } = router;
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleChangeView = useCallback(() => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.DocumentNotification,
    );
    router.push({
      pathname: '/edit-patient',
      query: { ...query, patientId },
    });
    dispatch(notificationCentersActions.delete(notification.id));
    closeModal();
  }, [router, patientId, notification]);

  const documentLabelCategory = documentLabelCategories.find(
    d => d.id === category,
  );

  return (
    <NotificationWrapper
      notification={notification}
      className={classes.notificationDocumentRoot}
    >
      <Typography variant="subtitle" className="notificationTitle">
        {t('notification_document_title')}
      </Typography>

      <Typography className="documentType" onClick={handleChangeView}>
        {documentLabelCategory && t(documentLabelCategory.name)}
      </Typography>

      <div className="text-neutral-secondary flex items-center gap-1">
        <FontAwesomeIcon name="user:solid" className="h-3 w-3" />
        <PatientName
          field={PatientLabelField.NAME}
          partialPatient={{
            firstName: firstNamePatient,
            lastName: lastNamePatient,
          }}
          wrapperComponent={Fragment}
        />
        <InternetPatientIcon
          partialPatient={
            {
              profileId: profileIdPatient,
            } as PatientGetDTO
          }
          className="h-3 w-3"
        />
      </div>
    </NotificationWrapper>
  );
};

export default NotificationDocumentView;
